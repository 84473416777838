import {
    ADD_ALL_GROUPS,
    EDIT_GROUP_BY_INDEX,
    REMOVE_ALL_GROUPS,
    REMOVE_GROUP_BY_INDEX,
} from "./groupTypes.js";

export const addAllGroups = (groups = []) => {
    return {
        type: ADD_ALL_GROUPS,
        payload: groups,
    };
};

export const removeAllGroups = () => {
    return {
        type: REMOVE_ALL_GROUPS,
    };
};

export const editGroupByIndex = (updatedGroup = {}, id) => {
    return {
        type: EDIT_GROUP_BY_INDEX,
        payload: { updatedGroup: updatedGroup, id: id },
    };
};

export const removeGroupByIndex = (index) => {
    return {
        type: REMOVE_GROUP_BY_INDEX,
        payload: index,
    };
};
