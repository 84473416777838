import {
    ADD_ALL_TEMPLATES,
    EDIT_TEMPLATE_BY_INDEX,
    REMOVE_ALL_TEMPLATES,
    REMOVE_TEMPLATE_BY_INDEX,
} from "./templateTypes.js";

export const initialState = {
    templates: [],
};

const templateReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_ALL_TEMPLATES:
            return {
                ...state,
                templates: action.payload,
            };
        case REMOVE_ALL_TEMPLATES:
            return {
                ...state,
                templates: [],
            };
        case EDIT_TEMPLATE_BY_INDEX:
            return {
                ...state,
                templates: state.templates.map((template) => {
                    if (template.id === action.payload.id)
                        template = action.payload.updatedTemplate;
                    return template;
                }),
            };
        case REMOVE_TEMPLATE_BY_INDEX:
            return {
                ...state,
                templates: [
                    // here action.payload is the index of the template to be deleted
                    ...state.templates.slice(0, action.payload),
                    ...state.templates.slice(action.payload + 1),
                ],
            };
        default:
            return state;
    }
};

export default templateReducer;
