import { Button } from "reactstrap";

const MeetingHeaderWithHistory = (props) => {
    const {
        headingText,
        getMeetingHistory,
        id,
        jwt,
        setMeetingHistory,
        setIsShowMeetingHistory,
        isShowMeetingHistory,
        showMeetingHistory,
        meetingHistory,
    } = props;
    return (
        <>
            <h1 className="d-inline">{headingText}</h1>
            <Button
                className="float-end"
                color="primary"
                onClick={(e) =>
                    getMeetingHistory(
                        e,
                        id,
                        jwt,
                        setMeetingHistory,
                        setIsShowMeetingHistory
                    )
                }
            >
                Show History
            </Button>
            {isShowMeetingHistory
                ? showMeetingHistory(
                      meetingHistory,
                      isShowMeetingHistory,
                      setIsShowMeetingHistory
                  )
                : null}
            <hr />
        </>
    );
};

export default MeetingHeaderWithHistory;
