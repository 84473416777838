import {
    ADD_ALL_PUBILC_SUBSCRIPTIONS,
    EDIT_PUBILC_SUBSCRIPTION_BY_INDEX,
    REMOVE_ALL_PUBILC_SUBSCRIPTIONS,
    REMOVE_PUBILC_SUBSCRIPTION_BY_INDEX,
} from "./publicSubscriptionTypes.js";

export const initialState = {
    publicSubscriptions: [],
};

const publicSubscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_ALL_PUBILC_SUBSCRIPTIONS:
            return {
                ...state,
                publicSubscriptions: action.payload,
            };
        case REMOVE_ALL_PUBILC_SUBSCRIPTIONS:
            return {
                ...state,
                publicSubscriptions: [],
            };
        case EDIT_PUBILC_SUBSCRIPTION_BY_INDEX:
            return {
                ...state,
                publicSubscriptions: state.publicSubscriptions.map(
                    (publicSubscription) => {
                        if (publicSubscription.id === action.payload.id)
                            publicSubscription = action.payload.updatedGroup;
                        return publicSubscription;
                    }
                ),
            };
        case REMOVE_PUBILC_SUBSCRIPTION_BY_INDEX:
            return {
                ...state,
                publicSubscriptions: [
                    // here action.payload is the index of the publicSubscription to be deleted
                    ...state.publicSubscriptions.slice(0, action.payload),
                    ...state.publicSubscriptions.slice(action.payload + 1),
                ],
            };
        default:
            return state;
    }
};

export default publicSubscriptionReducer;
