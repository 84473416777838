import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

function MeetingUpdatedModal() {
    return (
        <Modal isOpen={true} autoFocus={false}>
            <ModalHeader>Warning!</ModalHeader>
            <ModalBody>Meeting Updated. Please refresh!</ModalBody>
            <ModalFooter>
                <Button
                    color="success"
                    onClick={(e) => {
                        e.preventDefault();
                        window.location.reload(true);
                    }}
                >
                    Refresh
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default MeetingUpdatedModal;
