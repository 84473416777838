import axios from "axios";
import { DELETE_MEETING_BY_ID_URL } from "../../constants";
import {
    editMeetingByIndex,
    removeMeetingByIndex,
} from "../../redux/meeting/meetingActions";

const handleDeleteMeeting = async (
    index,
    meeting,
    jwt,
    dispatch,
    isHardDelete = false
) => {
    try {
        const response = await axios.delete(
            DELETE_MEETING_BY_ID_URL +
                `/${meeting.id}${isHardDelete ? "?delete=hard" : ""}`,
            {
                headers: { Authorization: `Bearer ${jwt}` },
            }
        );

        if (response.status === 204) {
            if (isHardDelete) dispatch(removeMeetingByIndex(index));
            else {
                meeting["is_active"] = 0;
                const updatedMeeting = meeting;
                dispatch(editMeetingByIndex(updatedMeeting, updatedMeeting.id));
            }
            return true;
        }
        return false;
    } catch (error) {
        return false;
    }
};
export default handleDeleteMeeting;
