import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Input,
    Label,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
} from "reactstrap";
import {
    GET_ALL_PUBLIC_GROUPS_URL,
    GET_ALL_PUBLIC_MEETINGS_URL,
    ITEMS_PER_PAGE_LIST,
    LOGIN_ROUTE,
    MEETING_SORT_BY_ENUM,
    SORT_ORDER_ENUM,
    SUBSCRIPTION_ROUTE,
} from "../../constants";
import ProjectTable from "../widgets/projectTable";
import {
    addAllMeetings,
    removeAllMeetings,
} from "../../redux/meeting/meetingActions";
import { Link, useParams } from "react-router-dom";
import TextField from "../widgets/textField";
import { getGroupByName, getGroupById } from "../utils/getGroupByNameOrId";

const PublicMeetings = () => {
    const meetings = useSelector((state) => state.meetingReducer.meetings);
    const dispatch = useDispatch();

    const { client_slug } = useParams();
    // get the value of the query parameter "group_id"
    const groupIdFromUrl = new URLSearchParams(window.location.search).get(
        "group_id"
    );

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [perPage, setPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const [sortBy, setSortBy] = useState(MEETING_SORT_BY_ENUM[0]);
    const [sortOrder, setSortOrder] = useState(SORT_ORDER_ENUM[0]);
    const [meetingName, setMeetingName] = useState("");
    const [meetingTitle, setMeetingTitle] = useState("");
    const [searchUrl, setSearchUrl] = useState(
        GET_ALL_PUBLIC_MEETINGS_URL.replace(":client_slug", client_slug) +
            `?per_page=${perPage}&page=${pageNumber}&order=${sortBy},${sortOrder}${
                groupIdFromUrl ? `&group=${groupIdFromUrl}` : ``
            }`
    );
    const [groups, setGroups] = useState([]);
    const [group, setGroup] = useState({});

    const LOCAL_START_DATE = "Start Date";
    const LOCAL_END_DATE = "End Date";
    const LOCAL_SORT = "Sort By";
    const LOCAL_SORT_ORDER = "Sort Order";
    const LOCAL_MEETING_NAME = "Meeting Name";
    const LOCAL_MEETING_TITLE = "Meeting Title";
    const LOCAL_GROUP = "Group";

    function handleInputChange(e, type) {
        e.preventDefault();

        if (type === LOCAL_START_DATE) setStartDate(e.target.value);
        if (type === LOCAL_END_DATE) setEndDate(e.target.value);
        if (type === LOCAL_SORT) setSortBy(e.target.value);
        if (type === LOCAL_SORT_ORDER) setSortOrder(e.target.value);
        if (type === LOCAL_MEETING_NAME) {
            setMeetingName(e.target.value);
            setMeetingTitle("");
        }
        if (type === LOCAL_MEETING_TITLE) {
            setMeetingTitle(e.target.value);
            setMeetingName("");
        }
        if (type === LOCAL_GROUP) {
            const matchedGroup = getGroupByName(e.target.value, groups);

            setGroup({
                name: matchedGroup["name"],
                id: matchedGroup["id"],
            });
        }
    }

    const updateSearchUrl = (e) => {
        let queryParameters = `?per_page=${perPage}&page=${pageNumber}&order=${sortBy},${sortOrder}`;

        if (meetingName !== "")
            queryParameters += `&search=name,${meetingName}`;
        else if (meetingTitle !== "")
            queryParameters += `&search=title,${meetingTitle}`;
        else if (groupIdFromUrl) queryParameters += `&group=${groupIdFromUrl}`;
        else if (group.name !== undefined && group.id !== undefined) {
            if (group.name !== "" && group.id !== -1) {
                queryParameters += `&group=${group.id}`;
            }
        }
        if (startDate) queryParameters += `&start_date=${startDate}`;
        if (endDate) queryParameters += `&end_date=${endDate}`;

        setSearchUrl(
            GET_ALL_PUBLIC_MEETINGS_URL.replace(":client_slug", client_slug) +
                queryParameters
        );
    };

    const fetchGroups = async () => {
        const response = await axios(
            GET_ALL_PUBLIC_GROUPS_URL.replace(":client_slug", client_slug) +
                `?per_page=50`
        );

        response.data.groups.data.unshift({ id: -1, name: "--select group--" });
        setGroups(response.data.groups.data);
        if (groupIdFromUrl) {
            const matchedGroup = getGroupById(
                groupIdFromUrl,
                response.data.groups.data
            );

            setGroup({
                name: matchedGroup["name"],
                id: matchedGroup["id"],
            });
        } else if (!group) setGroup(response.data.groups.data[0]);
    };

    async function fetchMeetings() {
        const response = await axios(searchUrl);

        let meetingsList = [];
        if (response.data.meetings.length !== 0) {
            response.data.meetings.data.forEach((meeting) => {
                meetingsList.push(meeting);
            });
            setTotalItems(response.data.meetings.total);

            dispatch(addAllMeetings(meetingsList));
        } else setTotalItems(0);
    }
    useEffect(() => {
        fetchGroups();
        updateSearchUrl();
        fetchMeetings();
        return () => {
            dispatch(removeAllMeetings());
        };
    }, [dispatch, perPage, pageNumber, searchUrl]);

    function handleItemsPerPageChange(e, newPerPage) {
        e.preventDefault();

        setPerPage(newPerPage);
        setPageNumber(1);
    }

    var paginationLinks = [];
    for (var i = 1; i <= Math.ceil(totalItems / perPage); i++) {
        paginationLinks.push(i);
    }

    return (
        <>
            <div className="container-lg">
                <div className="container-fluid">
                    <div className="page-header mt-3 d-lg-flex justify-content-between">
                        <h1>Meetings</h1>
                        <div>
                            <Link
                                to={SUBSCRIPTION_ROUTE.replace(
                                    ":client_slug",
                                    client_slug
                                )}
                                className="nounderline btn btn-success"
                            >
                                Go to Subcriptions
                            </Link>
                            <Link
                                to={LOGIN_ROUTE}
                                className="nounderline btn btn-primary ms-3"
                            >
                                Login
                            </Link>
                        </div>
                    </div>
                    <hr />
                    <Card>
                        <CardHeader>
                            <CardTitle>Filters</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    setPerPage(ITEMS_PER_PAGE_LIST[0]);
                                    setPageNumber(1);
                                    updateSearchUrl(e);
                                }}
                            >
                                <Row>
                                    <Col lg="3">
                                        <FormGroup floating>
                                            <Input
                                                id={LOCAL_START_DATE}
                                                name={LOCAL_START_DATE}
                                                type="date"
                                                value={startDate}
                                                className="d-inline"
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        e,
                                                        LOCAL_START_DATE
                                                    )
                                                }
                                            />
                                            <Label htmlFor={LOCAL_START_DATE}>
                                                {LOCAL_START_DATE}
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="3">
                                        <FormGroup floating>
                                            <Input
                                                id={LOCAL_END_DATE}
                                                name={LOCAL_END_DATE}
                                                type="date"
                                                value={endDate}
                                                className="d-inline"
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        e,
                                                        LOCAL_END_DATE
                                                    )
                                                }
                                            />
                                            <Label htmlFor={LOCAL_END_DATE}>
                                                {LOCAL_END_DATE}
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="3">
                                        <FormGroup floating>
                                            <Input
                                                id={LOCAL_SORT}
                                                name={LOCAL_SORT}
                                                type="select"
                                                value={sortBy}
                                                className="d-inline"
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        e,
                                                        LOCAL_SORT
                                                    )
                                                }
                                            >
                                                {MEETING_SORT_BY_ENUM.map(
                                                    (sort) => {
                                                        return (
                                                            <option key={sort}>
                                                                {sort}
                                                            </option>
                                                        );
                                                    }
                                                )}
                                            </Input>
                                            <Label htmlFor={LOCAL_SORT}>
                                                {LOCAL_SORT}
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="3">
                                        <FormGroup floating>
                                            <Input
                                                id={LOCAL_SORT_ORDER}
                                                name={LOCAL_SORT_ORDER}
                                                type="select"
                                                value={sortOrder}
                                                className="d-inline"
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        e,
                                                        LOCAL_SORT_ORDER
                                                    )
                                                }
                                            >
                                                {SORT_ORDER_ENUM.map(
                                                    (order) => {
                                                        return (
                                                            <option key={order}>
                                                                {order}
                                                            </option>
                                                        );
                                                    }
                                                )}
                                            </Input>
                                            <Label htmlFor={LOCAL_SORT_ORDER}>
                                                {LOCAL_SORT_ORDER}
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="4">
                                        <TextField
                                            label={LOCAL_MEETING_TITLE}
                                            placeholder={`Enter ${LOCAL_MEETING_TITLE}`}
                                            type="text"
                                            value={meetingTitle}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    LOCAL_MEETING_TITLE
                                                )
                                            }
                                        />
                                    </Col>
                                    <Col lg="4">
                                        <TextField
                                            label={LOCAL_MEETING_NAME}
                                            placeholder={`Enter ${LOCAL_MEETING_NAME}`}
                                            type="text"
                                            value={meetingName}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    LOCAL_MEETING_NAME
                                                )
                                            }
                                        />
                                    </Col>
                                    <Col lg="4">
                                        <FormGroup floating>
                                            <Input
                                                id={LOCAL_GROUP}
                                                name={LOCAL_GROUP}
                                                type="select"
                                                className="mb-2 mt-2"
                                                value={group.name}
                                                disabled={
                                                    groupIdFromUrl
                                                        ? true
                                                        : false
                                                }
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        e,
                                                        LOCAL_GROUP
                                                    )
                                                }
                                            >
                                                {groups.map((groupElement) => {
                                                    return (
                                                        <option
                                                            key={
                                                                groupElement.id
                                                            }
                                                        >
                                                            {groupElement.name}
                                                        </option>
                                                    );
                                                })}
                                            </Input>
                                            <Label htmlFor={LOCAL_GROUP}>
                                                {LOCAL_GROUP}
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Button type="submit" color="primary">
                                    Search
                                </Button>
                            </Form>
                        </CardBody>
                    </Card>
                    <div className="my-4">
                        Show
                        <Dropdown
                            isOpen={dropdownOpen}
                            toggle={toggle}
                            className="d-inline mx-2"
                        >
                            <DropdownToggle caret>{perPage}</DropdownToggle>
                            <DropdownMenu>
                                {ITEMS_PER_PAGE_LIST.map((itemPerPage) => {
                                    return (
                                        <DropdownItem
                                            key={itemPerPage}
                                            onClick={(e) =>
                                                handleItemsPerPageChange(
                                                    e,
                                                    itemPerPage
                                                )
                                            }
                                        >
                                            {itemPerPage}
                                        </DropdownItem>
                                    );
                                })}
                            </DropdownMenu>
                        </Dropdown>
                        entries
                    </div>
                    <ProjectTable data={meetings} type="PUBLIC MEETINGS" />
                    <Pagination
                        aria-label="Page navigation example"
                        className="justify-content-center"
                    >
                        {paginationLinks.map((paginationLink) => (
                            <PaginationItem
                                key={paginationLink}
                                active={pageNumber === paginationLink}
                            >
                                <PaginationLink
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (pageNumber !== paginationLink)
                                            setPageNumber(paginationLink);
                                    }}
                                >
                                    {paginationLink}
                                </PaginationLink>
                            </PaginationItem>
                        ))}
                    </Pagination>
                </div>
            </div>
        </>
    );
};

export default PublicMeetings;
