import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import { useHistory } from "react-router-dom";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import {
    GET_ALL_CLIENTS_URL,
    LOGIN_ROUTE,
    REGISTER_URL,
    USERS_ROUTE,
    USER_ROLES_ENUM,
} from "../../constants";
import { capitalize } from "../utils/stringUtils";
import {
    validateEmail,
    validateEmptyField,
    validatePassword,
} from "../utils/validate";
import ProjectNavbar from "../widgets/projectNavbar";
import TextField from "../widgets/textField";

const CreateUser = () => {
    const jwt = useSelector((state) => state.authReducer.jwt);
    const currentUser = useSelector((state) => state.authReducer.currentUser);
    const history = useHistory();

    const [firstname, setFirstname] = useState(null);
    const [firstnameError, setFirstnameError] = useState(null);
    const [lastname, setLastname] = useState(null);
    const [lastnameError, setLastnameError] = useState(null);
    const [role, setRole] = useState("USER");
    const [current_client, setCurrentClient] = useState({
        name: "",
        id: null,
    }); // by-default add no current_client to the user
    const [email, setEmail] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState(null);
    const [createUserError, setCreateUserError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const [clients, setClients] = useState([]);

    const LOCAL_FIRSTNAME = "First Name";
    const LOCAL_LASTNAME = "Last Name";
    const LOCAL_ROLE = "Role";
    const LOCAL_CLIENT = "Client";
    const LOCAL_EMAIL = "Email";
    const LOCAL_PASSWORD = "Password";
    const LOCAL_CONFIRM_PASSWORD = "Confirm Password";

    function handleInputChange(e, type) {
        e.preventDefault();

        clearSuccessAndErrorMessages();
        if (type === LOCAL_FIRSTNAME) {
            e.target.value = capitalize(e.target.value);
            setFirstname(e.target.value);
        }
        if (type === LOCAL_LASTNAME) {
            e.target.value = capitalize(e.target.value);
            setLastname(e.target.value);
        }
        if (type === LOCAL_ROLE) setRole(e.target.value);
        if (type === LOCAL_CLIENT) {
            const matchedClient = getClientByName(e.target.value);

            setCurrentClient({
                name: matchedClient["name"],
                id: matchedClient["id"],
            });
        }
        if (type === LOCAL_EMAIL) setEmail(e.target.value);
        if (type === LOCAL_PASSWORD) setPassword(e.target.value);
        if (type === LOCAL_CONFIRM_PASSWORD) setConfirmPassword(e.target.value);
    }

    const getClientByName = (name) => {
        return clients.find(function (current_client) {
            return current_client.name === name;
        });
    };

    async function fetchClients() {
        const response = await axios.get(GET_ALL_CLIENTS_URL + "?per_page=50", {
            headers: { Authorization: `Bearer ${jwt}` },
        });

        // by-default add no current_client to the user
        let clientsList = [];
        response.data.clients.data.forEach((current_client) => {
            // getting only the list of active clients
            if (current_client.is_active)
                clientsList.push({
                    name: current_client.name,
                    id: current_client.id,
                });
        });
        setCurrentClient(clientsList[0]);
        setClients(clientsList);
    }

    useEffect(() => {
        fetchClients();
    }, []);

    function clearSuccessAndErrorMessages() {
        setFirstnameError(null);
        setLastnameError(null);
        setEmailError(null);
        setPasswordError(null);
        setConfirmPasswordError(null);
        setCreateUserError(null);
        setSuccessMessage(null);
    }

    function validateAll() {
        if (!validateEmptyField(firstname)) {
            setFirstnameError("Please fill this field");
            return false;
        }
        if (!validateEmptyField(lastname)) {
            setLastnameError("Please fill this field");
            return false;
        }
        if (!validateEmail(email)) {
            setEmailError("Please provide a valid email");
            return false;
        }
        if (!validatePassword(password)) {
            setPasswordError("Please provide a valid password");
            return false;
        } else if (password !== confirmPassword) {
            setConfirmPasswordError(
                "Password and Password Confirmation must be identical!"
            );
            return false;
        }
        return true;
    }

    const handleCreateUser = async (e) => {
        e.preventDefault();

        clearSuccessAndErrorMessages();

        if (validateAll()) {
            let newUserObject = {};
            newUserObject["first_name"] = firstname.trim();
            newUserObject["last_name"] = lastname.trim();
            newUserObject["role"] = role;
            if (currentUser.role === "SUPERUSER") {
                if (current_client.name !== "None")
                    newUserObject["current_client"] = current_client.id;
            } else newUserObject["current_client"] = currentUser.current_client;
            newUserObject["email"] = email.trim();
            newUserObject["password"] = password;
            newUserObject["password_confirmation"] = confirmPassword;
            try {
                const response = await axios.post(REGISTER_URL, newUserObject, {
                    headers: { Authorization: `Bearer ${jwt}` },
                });
                if (response.status === 201) {
                    setSuccessMessage("User created successfully");
                    history.push(USERS_ROUTE);
                }
            } catch (error) {
                if (error.response) {
                    setCreateUserError(error.response.data.message);
                    if (error.response.data.errors)
                        if (error.response.data.errors.email)
                            setEmailError(error.response.data.errors.email);
                }
            }
        }
    };

    return (
        <>
            {jwt === null ? (
                // if the user is not logged in then redirect to login
                <Redirect to={LOGIN_ROUTE} />
            ) : (
                // ) : isSessionExpired ? (
                //     <ErrorModal />
                <div className="container-lg">
                    <ProjectNavbar />
                    <div className="container-fluid">
                        <div className="page-header">
                            <h1 className="d-inline">Create User</h1>
                        </div>
                        <hr />
                        <Form onSubmit={handleCreateUser}>
                            <Row>
                                <Col>
                                    <TextField
                                        label={LOCAL_FIRSTNAME}
                                        placeholder={`Enter ${LOCAL_FIRSTNAME}`}
                                        type="text"
                                        autofocus={true}
                                        value={firstname ?? ""}
                                        onChange={(e) =>
                                            handleInputChange(
                                                e,
                                                LOCAL_FIRSTNAME
                                            )
                                        }
                                    />
                                    {firstnameError !== null ? (
                                        <p className="text-danger">
                                            {firstnameError}
                                        </p>
                                    ) : null}
                                </Col>
                                <Col>
                                    <TextField
                                        label={LOCAL_LASTNAME}
                                        placeholder={`Enter ${LOCAL_LASTNAME}`}
                                        type="text"
                                        value={lastname ?? ""}
                                        onChange={(e) =>
                                            handleInputChange(e, LOCAL_LASTNAME)
                                        }
                                    />
                                    {lastnameError !== null ? (
                                        <p className="text-danger">
                                            {lastnameError}
                                        </p>
                                    ) : null}
                                </Col>
                            </Row>
                            <FormGroup floating>
                                <Input
                                    id={LOCAL_ROLE}
                                    name={LOCAL_ROLE}
                                    type="select"
                                    value={role}
                                    onChange={(e) =>
                                        handleInputChange(e, LOCAL_ROLE)
                                    }
                                >
                                    {USER_ROLES_ENUM.map((role, index) => {
                                        return (
                                            <option key={role}>{role}</option>
                                        );
                                    })}
                                </Input>
                                <Label htmlFor={LOCAL_ROLE}>{LOCAL_ROLE}</Label>
                            </FormGroup>
                            {currentUser.role === "SUPERUSER" ? (
                                <>
                                    <FormGroup floating>
                                        <Input
                                            id={LOCAL_CLIENT}
                                            name={LOCAL_CLIENT}
                                            type="select"
                                            className="mb-2"
                                            value={current_client.name}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    LOCAL_CLIENT
                                                )
                                            }
                                        >
                                            {clients.map(
                                                (current_client, index) => {
                                                    return (
                                                        <option
                                                            key={
                                                                current_client.id
                                                            }
                                                        >
                                                            {
                                                                current_client.name
                                                            }
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </Input>
                                        <Label htmlFor={LOCAL_CLIENT}>
                                            {LOCAL_CLIENT}
                                        </Label>
                                    </FormGroup>
                                </>
                            ) : null}
                            <TextField
                                label={LOCAL_EMAIL}
                                placeholder={`Enter ${LOCAL_EMAIL}`}
                                type="email"
                                value={email ?? ""}
                                onChange={(e) =>
                                    handleInputChange(e, LOCAL_EMAIL)
                                }
                            />
                            {emailError !== null ? (
                                <p className="text-danger">{emailError}</p>
                            ) : null}
                            <TextField
                                label={LOCAL_PASSWORD}
                                placeholder={`Enter ${LOCAL_PASSWORD}`}
                                type="password"
                                value={password ?? ""}
                                onChange={(e) =>
                                    handleInputChange(e, LOCAL_PASSWORD)
                                }
                            />
                            {passwordError !== null ? (
                                <p className="text-danger">{passwordError}</p>
                            ) : null}
                            <TextField
                                label={LOCAL_CONFIRM_PASSWORD}
                                placeholder={`Enter ${LOCAL_CONFIRM_PASSWORD}`}
                                type="password"
                                value={confirmPassword ?? ""}
                                onChange={(e) =>
                                    handleInputChange(e, LOCAL_CONFIRM_PASSWORD)
                                }
                            />
                            {confirmPasswordError !== null ? (
                                <p className="text-danger">
                                    {confirmPasswordError}
                                </p>
                            ) : null}
                            {createUserError !== null ? (
                                <p className="text-danger">{createUserError}</p>
                            ) : null}
                            {successMessage !== null ? (
                                <p className="text-success">{successMessage}</p>
                            ) : null}
                            <Button
                                color="primary"
                                type="submit"
                                className="mb-5"
                            >
                                Save User
                            </Button>
                        </Form>
                    </div>
                </div>
            )}
        </>
    );
};

export default CreateUser;
