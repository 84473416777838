import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from "react-router-dom";
import Login from "./components/pages/login.js";
import {
    CLIENTS_ROUTE,
    CONFIRM_PUBLIC_SUBSCRIPTION_ROUTE,
    CREATE_CLIENT_ROUTE,
    CREATE_GROUP_ROUTE,
    CREATE_MEETING_ROUTE,
    CREATE_TEMPLATE_ROUTE,
    CREATE_USER_ROUTE,
    EDIT_CLIENT_ROUTE,
    EDIT_GROUP_ROUTE,
    EDIT_MEETING_ROUTE,
    EDIT_TEMPLATE_ROUTE,
    EDIT_USER_ROUTE,
    FORGOT_PASSWORD_ROUTE,
    GROUPS_ROUTE,
    LOGIN_ROUTE,
    LS_CURRENT_USER,
    LS_SESSION_JWT,
    MEETINGS_ROUTE,
    PUBLIC_MEETING_ROUTE,
    PUBLIC_SUBSCRIPTIONS_ROUTE,
    RESET_PASSWORD_ROUTE,
    SUBSCRIPTION_ROUTE,
    TEMPLATES_ROUTE,
    UNSUBSCRIPTION_ROUTE,
    USERS_ROUTE,
    VIEW_MEETING_ROUTE,
    VIEW_PUBLIC_MEETING_ROUTE,
    VIEW_TEMPLATE_ROUTE,
} from "./constants.js";
import { useDispatch } from "react-redux";
import { addCurrentUser, setAuthJwt } from "./redux/auth/authActions";
import ForgotPassword from "./components/pages/forgotPassword.js";
import PasswordReset from "./components/pages/resetPassword.js";
import Meetings from "./components/pages/meetings.js";
import Clients from "./components/pages/clients.js";
import Groups from "./components/pages/groups.js";
import Users from "./components/pages/users.js";
import EditUser from "./components/pages/editUser.js";
import CreateUser from "./components/pages/createUser.js";
import EditClient from "./components/pages/editClient.js";
import CreateClient from "./components/pages/createClient.js";
import CreateGroup from "./components/pages/createGroup.js";
import EditGroup from "./components/pages/editGroup.js";
import Templates from "./components/pages/templates.js";
import CreateTemplate from "./components/pages/createTemplate.js";
import ViewTemplate from "./components/pages/viewTemplate.js";
import PageNotFound from "./components/pages/pageNotFound.js";
import EditTemplate from "./components/pages/editTemplate.js";
import ViewMeeting from "./components/pages/viewMeeting.js";
import CreateMeeting from "./components/pages/createMeeting.js";
import EditMeeting from "./components/pages/editMeeting.js";
import Subscription from "./components/pages/subscription.js";
import PublicMeetings from "./components/pages/publicMeetings.js";
import ViewPublicMeeting from "./components/pages/viewPublicMeeting.js";
import Unsubscribe from "./components/pages/unsubscribe.js";
import PublicSusbcriptions from "./components/pages/publicSubscriptions.js";
import ConfirmPublicSubscriptions from "./components/pages/confirmPublicSubsription.js";

function App() {
    const dispatch = useDispatch();

    // check if session variable has jwt or not
    let session_jwt = localStorage.getItem(LS_SESSION_JWT);
    session_jwt = session_jwt !== null ? JSON.parse(session_jwt) : null;

    if (
        session_jwt !== null &&
        session_jwt.value.token !== null &&
        session_jwt.value.token !== "" &&
        session_jwt.expiry > new Date().getTime()
    ) {
        dispatch(setAuthJwt(session_jwt.value.token));

        // if jwt is available then we can check and store the current admin as well
        let current_user = localStorage.getItem(LS_CURRENT_USER);
        current_user = current_user !== null ? JSON.parse(current_user) : null;
        dispatch(addCurrentUser(current_user.value));
    }

    return (
        <Router>
            <div className="App">
                <Switch>
                    <Route
                        exact
                        path="/"
                        render={() => {
                            return <Redirect to={LOGIN_ROUTE} />;
                        }}
                    />
                    <Route path={LOGIN_ROUTE} exact component={Login} />
                    <Route
                        path={FORGOT_PASSWORD_ROUTE}
                        exact
                        component={ForgotPassword}
                    />
                    <Route
                        path={RESET_PASSWORD_ROUTE}
                        exact
                        component={PasswordReset}
                    />
                    <Route path={MEETINGS_ROUTE} exact component={Meetings} />
                    <Route path={TEMPLATES_ROUTE} exact component={Templates} />
                    <Route path={CLIENTS_ROUTE} exact component={Clients} />
                    <Route path={GROUPS_ROUTE} exact component={Groups} />
                    <Route
                        path={PUBLIC_SUBSCRIPTIONS_ROUTE}
                        exact
                        component={PublicSusbcriptions}
                    />
                    <Route path={USERS_ROUTE} exact component={Users} />
                    <Route path={EDIT_USER_ROUTE} exact component={EditUser} />
                    <Route
                        path={EDIT_CLIENT_ROUTE}
                        exact
                        component={EditClient}
                    />
                    <Route
                        path={EDIT_GROUP_ROUTE}
                        exact
                        component={EditGroup}
                    />
                    <Route
                        path={CREATE_USER_ROUTE}
                        exact
                        component={CreateUser}
                    />
                    <Route
                        path={CREATE_CLIENT_ROUTE}
                        exact
                        component={CreateClient}
                    />
                    <Route
                        path={CREATE_GROUP_ROUTE}
                        exact
                        component={CreateGroup}
                    />
                    <Route
                        path={CREATE_TEMPLATE_ROUTE}
                        exact
                        component={CreateTemplate}
                    />
                    <Route
                        path={VIEW_TEMPLATE_ROUTE}
                        exact
                        component={ViewTemplate}
                    />
                    <Route
                        path={EDIT_TEMPLATE_ROUTE}
                        exact
                        component={EditTemplate}
                    />
                    <Route
                        path={CREATE_MEETING_ROUTE}
                        exact
                        component={CreateMeeting}
                    />
                    <Route
                        path={VIEW_MEETING_ROUTE}
                        exact
                        component={ViewMeeting}
                    />
                    <Route
                        path={EDIT_MEETING_ROUTE}
                        exact
                        component={EditMeeting}
                    />
                    <Route
                        path={SUBSCRIPTION_ROUTE}
                        exact
                        component={Subscription}
                    />
                    <Route
                        path={PUBLIC_MEETING_ROUTE}
                        exact
                        component={PublicMeetings}
                    />
                    <Route
                        path={VIEW_PUBLIC_MEETING_ROUTE}
                        exact
                        component={ViewPublicMeeting}
                    />
                    <Route
                        path={UNSUBSCRIPTION_ROUTE}
                        exact
                        component={Unsubscribe}
                    />
                    <Route
                        path={CONFIRM_PUBLIC_SUBSCRIPTION_ROUTE}
                        exact
                        component={ConfirmPublicSubscriptions}
                    />
                    <Route component={PageNotFound} />
                </Switch>
            </div>
        </Router>
    );
}

export default App;
