import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useParams } from "react-router";
import { Button } from "reactstrap";
import {
    DOWNLOAD_MEETING_FILES_URL,
    DOWNLOAD_MEETING_PACKETS_URL,
    GET_MEETING_BY_ID_URL,
    LOGIN_ROUTE,
    EDIT_MEETING_ROUTE
} from "../../constants";
import ProjectNavbar from "../widgets/projectNavbar";
import axios from "axios";
import SunEditor from "suneditor-react";
import "./../../styles/viewMeeting.css";
import { showMeetingHistory } from "../widgets/meetingHistory";
import { getMeetingHistory } from "../utils/getMeetingHistory";
import MeetingHeaderWithHistory from "../widgets/meetingHeaderWithHistory";
import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { getLocalTimeFromUTC } from "../utils/timeConverts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";

const ViewMeeting = () => {
    const jwt = useSelector((state) => state.authReducer.jwt);
    const currentUser = useSelector((state) => state.authReducer.currentUser);
    const { id } = useParams();
    const componentRef = useRef();

    const [meeting, setMeeting] = useState({
        name: null,
        title: null,
        date: null,
        start_time: null,
        end_time: null,
        topic_numbering: null,
        items: [
            {
                description: null,
                order: null,
                is_active: null,
                minute: null,
                type: null,
            },
        ],
    });
    const [meetingHistory, setMeetingHistory] = useState(null);
    const [isShowMeetingHistory, setIsShowMeetingHistory] = useState(false);

    const location = useLocation()
    const {is_minutes} = location.state == null ? false : location.state;

    const fetchMeeting = async () => {
        const response = await axios.get(GET_MEETING_BY_ID_URL + `/${id}`, {
            headers: { Authorization: `Bearer ${jwt}` },
        });

        const startTime = getLocalTimeFromUTC(
            `${response.data.meeting.start_time}Z`,
            currentUser.current_client_timezone
        );
        response.data.meeting.start_time = startTime.toTimeString();
        response.data.meeting.date = startTime.toDateString();
        const endTime = getLocalTimeFromUTC(
            `${response.data.meeting.end_time}Z`
        );
        response.data.meeting.end_time = endTime.toTimeString();
        setMeeting(response.data.meeting);
    };

    useEffect(() => {
        fetchMeeting();
    }, []);

    return (
        <>
            {jwt === null ? (
                // if the user is not logged in then redirect to login
                <Redirect to={LOGIN_ROUTE} />
            ) : (
                <div className="container-lg">
                    <ProjectNavbar />
                    <div className="container-fluid">
                        <MeetingHeaderWithHistory
                            headingText="View Meeting"
                            getMeetingHistory={getMeetingHistory}
                            id={id}
                            jwt={jwt}
                            setMeetingHistory={setMeetingHistory}
                            setIsShowMeetingHistory={setIsShowMeetingHistory}
                            isShowMeetingHistory={isShowMeetingHistory}
                            showMeetingHistory={showMeetingHistory}
                            meetingHistory={meetingHistory}
                        />
                        <ReactToPrint
                            trigger={() => (
                                <Button color="success" className="float-end">
                                    Print / Download
                                </Button>
                            )}
                            content={() => componentRef.current}
                        />
                        <div id="edit-buttons">
                            <a
                                key={meeting.id}
                                href={EDIT_MEETING_ROUTE.replace(
                                    ":id",
                                    meeting.id
                                )}
                                className="btn-info btn"
                                >
                                    Edit Meeting
                                </a>
                            {meeting.is_finalized ? 
                            <a
                                key={meeting.id}
                                href={DOWNLOAD_MEETING_PACKETS_URL.replace(
                                    ":id",
                                    meeting.id
                                )}
                                className="btn-info btn"
                                >
                                    View Agenda Packet
                                </a> :
                                null
                            }
                        </div>
                        <div ref={componentRef} className="p-3">
                            <h2>Meeting Agenda</h2>
                            <p>
                                <span className="fw-bold">Name: </span>{" "}
                                {meeting.name}
                            </p>
                            <p>
                                <span className="fw-bold">Title: </span>{" "}
                                {meeting.title}
                            </p>
                            <p>
                                <span className="fw-bold">Date: </span>{" "}
                                {meeting.date}
                            </p>
                            <p>
                                <span className="fw-bold">Start Time: </span>{" "}
                                {meeting.start_time}
                            </p>
                            <p>
                                <span className="fw-bold">End Time: </span>{" "}
                                {meeting.end_time}
                            </p>
                            <p>
                                <span className="fw-bold">Group: </span>{" "}
                                {meeting.group_name}
                            </p>
                            {/* agendas */}
                            <div className="mb-2">
                                <ol type={meeting.topic_numbering}>
                                    {meeting.items.map((item, itemIndex) => {
                                        return item.type === "AGENDA" ? (
                                            /* agenda */
                                            <div
                                                key={`Agenda ${item.id}`}
                                                className="view-suneditor"
                                            >
                                                <SunEditor
                                                    defaultValue={
                                                        item.description
                                                    }
                                                    setDefaultStyle="height: auto"
                                                    setOptions={{
                                                        buttonList: [],
                                                    }}
                                                    disable={true}
                                                />
                                            </div>
                                        ) : (
                                            /* topic */
                                            <li
                                                key={`Topic ${item.id}`}
                                                className={`view-suneditor ${meeting.topic_numbering?"li-marker-bold":"d-block"}`}
                                            >
                                                <span className="fw-bold">
                                                    {item.name}
                                                </span>
                                                {item.description !== null ? (
                                                    <SunEditor
                                                        defaultValue={
                                                            item.description
                                                        }
                                                        setDefaultStyle="height: auto"
                                                        setOptions={{
                                                            buttonList: [],
                                                        }}
                                                        disable={true}
                                                    />
                                                ) : null}
                                                {item.minute && is_minutes ? (
                                                    <div className="border p-2 w-100">
                                                        {item.minute}
                                                    </div>
                                                ) : null}
                                                {item.files && is_minutes
                                                    ? item.files.map((file) => {
                                                          return (
                                                              <a
                                                                  key={file.id}
                                                                  href={DOWNLOAD_MEETING_FILES_URL.replace(
                                                                      ":id",
                                                                      file.id
                                                                  )}
                                                                  className="d-block"
                                                              >
                                                                  <Button className="border-0 bg-success mt-2 mb-2">
                                                                      <FontAwesomeIcon
                                                                          icon={
                                                                              faDownload
                                                                          }
                                                                          size="sm"
                                                                          title="File Download"
                                                                          className="me-2"
                                                                      />
                                                                      {
                                                                          file.description
                                                                      }
                                                                  </Button>
                                                              </a>
                                                          );
                                                      })
                                                    : null}
                                                {item.minute_files
                                                    ? item.minute_files.map(
                                                          (file) => {
                                                              return (
                                                                  <a
                                                                      key={
                                                                          file.id
                                                                      }
                                                                      href={DOWNLOAD_MEETING_FILES_URL.replace(
                                                                          ":id",
                                                                          file.id
                                                                      )}
                                                                  >
                                                                      <Button className="border-0 bg-success mt-2 mb-2">
                                                                          <FontAwesomeIcon
                                                                              icon={
                                                                                  faDownload
                                                                              }
                                                                              size="sm"
                                                                              title="File Download"
                                                                              className="me-2"
                                                                          />
                                                                          {
                                                                              file.description
                                                                          }
                                                                      </Button>
                                                                  </a>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </li>
                                        );
                                    })}
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ViewMeeting;
