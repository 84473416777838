import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import { useHistory } from "react-router-dom";
import { Button, Form, Label } from "reactstrap";
import { GET_ALL_USERS_URL, LOGIN_ROUTE } from "../../constants";
import handleCreateGroup from "../utils/handleCreateGroup";
import { validateEmptyField } from "../utils/validate";
import ProjectNavbar from "../widgets/projectNavbar";
import TextField from "../widgets/textField";
import "./../../styles/createGroup.css";
import { capitalize } from "../utils/stringUtils";

const CreateGroup = () => {
    const jwt = useSelector((state) => state.authReducer.jwt);
    const history = useHistory();

    const [name, setName] = useState(null);
    const [nameError, setNameError] = useState(null);
    const [createGroupError, setCreateGroupError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const [users, setUsers] = useState([]);
    const [usersSelection, setUsersSelection] = useState([]);
    const [usersSelected, setUsersSelected] = useState([]);
    const [usersSelectedError, setUsersSelectedError] = useState(null);

    const LOCAL_NAME = "Name";

    function handleInputChange(e, type) {
        e.preventDefault();

        clearSuccessAndErrorMessages();
        if (type === LOCAL_NAME) {
            e.target.value = capitalize(e.target.value);
            setName(e.target.value);
        }
    }

    async function fetchUsers() {
        const response = await axios.get(GET_ALL_USERS_URL + "?per_page=50", {
            headers: { Authorization: `Bearer ${jwt}` },
        });

        let usersList = [];
        response.data.users.data.forEach((user) => {
            // getting only the list of active users
            if (user.is_active)
                usersList.push({
                    name: `${user.last_name}, ${user.first_name}`,
                    id: user.id,
                });
        });
        setUsers(usersList);
        setUsersSelection(usersList);
    }

    useEffect(() => {
        fetchUsers();
    }, []);

    function clearSuccessAndErrorMessages() {
        setNameError(null);
        setCreateGroupError(null);
        setSuccessMessage(null);
    }

    function validateAll() {
        if (!validateEmptyField(name)) {
            setNameError("Please fill this field!");
            return false;
        }
        if (usersSelected.length === 0) {
            setUsersSelectedError("Please select at least one user!");
            return false;
        }
        return true;
    }

    const userSelectionToSelected = (e, selectedUser) => {
        e.preventDefault();
        let usersSelectedList = usersSelected;
        usersSelectedList.push(selectedUser);

        setUsersSelected(usersSelectedList);

        let usersSelectionList = usersSelection;
        usersSelectionList = usersSelectionList.filter(function (user) {
            return user.id !== selectedUser.id;
        });
        setUsersSelection(usersSelectionList);
    };

    const userSelectedToSelection = (e, nonSelectedUser) => {
        e.preventDefault();

        let usersSelectionList = usersSelection;
        usersSelectionList.push(nonSelectedUser);

        setUsersSelection(usersSelectionList);

        let usersSelectedList = usersSelected;
        usersSelectedList = usersSelectedList.filter(function (user) {
            return user.id !== nonSelectedUser.id;
        });

        setUsersSelected(usersSelectedList);
    };

    return (
        <>
            {jwt === null ? (
                // if the user is not logged in then redirect to login
                <Redirect to={LOGIN_ROUTE} />
            ) : (
                <div className="container-lg">
                    <ProjectNavbar />
                    <div className="container-fluid">
                        <div className="page-header">
                            <h1 className="d-inline">Create Group</h1>
                        </div>
                        <hr />
                        <Form
                            onSubmit={(e) =>
                                handleCreateGroup(
                                    e,
                                    clearSuccessAndErrorMessages,
                                    validateAll,
                                    name,
                                    usersSelected,
                                    jwt,
                                    setSuccessMessage,
                                    setCreateGroupError,
                                    setNameError,
                                    history
                                )
                            }
                        >
                            <TextField
                                label={LOCAL_NAME}
                                placeholder={`Enter ${LOCAL_NAME}`}
                                type="text"
                                autofocus={true}
                                value={name ?? ""}
                                onChange={(e) =>
                                    handleInputChange(e, LOCAL_NAME)
                                }
                            />
                            {nameError !== null ? (
                                <p className="text-danger">{nameError}</p>
                            ) : null}
                            <Label>Users</Label>
                            <div className="label-container d-flex justify-content-between mt-2">
                                <Label>Client Users</Label>
                                <Label>Selected Users</Label>
                            </div>
                            <div className="user-selection-container d-flex justify-content-between mb-4">
                                <div
                                    className="user-selection d-inline border rounded"
                                    width="70%"
                                >
                                    <ul>
                                        {usersSelection.map((user, index) => {
                                            return (
                                                <li
                                                    key={user.id}
                                                    onClick={(e) =>
                                                        userSelectionToSelected(
                                                            e,
                                                            user
                                                        )
                                                    }
                                                >
                                                    {user.name}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                                <div
                                    className="user-selected d-inline border rounded"
                                    width="70%"
                                >
                                    <ul>
                                        {usersSelected.map((user, index) => {
                                            return (
                                                <li
                                                    key={user.id}
                                                    onClick={(e) =>
                                                        userSelectedToSelection(
                                                            e,
                                                            user
                                                        )
                                                    }
                                                >
                                                    {user.name}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                            {usersSelectedError !== null ? (
                                <p className="text-danger">
                                    {usersSelectedError}
                                </p>
                            ) : null}
                            {createGroupError !== null ? (
                                <p className="text-danger">
                                    {createGroupError}
                                </p>
                            ) : null}
                            {successMessage !== null ? (
                                <p className="text-success">{successMessage}</p>
                            ) : null}
                            <Button color="primary" type="submit">
                                Save Group
                            </Button>
                        </Form>
                    </div>
                </div>
            )}
        </>
    );
};

export default CreateGroup;
