import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router";
import { Alert, Button, Form } from "reactstrap";
import {
    EDIT_CLIENT_BY_ID_URL,
    GET_CLIENT_BY_ID_URL,
    LOGIN_ROUTE,
} from "../../constants";
import { validateClientSlug, validateEmptyField } from "../utils/validate";
import ProjectNavbar from "../widgets/projectNavbar";
import TextField from "../widgets/textField";
import TimezoneSelect from "react-timezone-select";
import { editCurrentUser } from "../../redux/auth/authActions";
import { capitalize } from "../utils/stringUtils";

const EditClient = () => {
    const jwt = useSelector((state) => state.authReducer.jwt);
    const currentUser = useSelector((state) => state.authReducer.currentUser);
    const { id } = useParams();

    const dispatch = useDispatch();

    const [client, setClient] = useState(null);
    const [name, setName] = useState(null);
    const [nameError, setNameError] = useState(null);
    const [clientSlug, setClientSlug] = useState(null);
    const [clientSlugError, setClientSlugError] = useState(null);
    const [clientEditError, setClientEditError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [selectedTimezone, setSelectedTimezone] = useState();
    const [labelStyle, setLabelStyle] = useState("abbrev");

    const LOCAL_NAME = "Name";
    const LOCAL_CLIENT_SLUG = "Client Slug";

    function handleInputChange(e, type) {
        e.preventDefault();

        clearSuccessAndErrorMessages();
        if (type === LOCAL_NAME) {
            e.target.value = capitalize(e.target.value);
            setName(e.target.value);
        }
        if (type === LOCAL_CLIENT_SLUG) setClientSlug(e.target.value);
    }

    async function fetchClient() {
        const response = await axios.get(GET_CLIENT_BY_ID_URL + `/${id}`, {
            headers: { Authorization: `Bearer ${jwt}` },
        });

        setName(response.data.client.name);
        setClientSlug(response.data.client.client_slug);
        setSelectedTimezone({ value: response.data.client.timezone });
        setClient(response.data.client);
    }

    useEffect(() => {
        fetchClient();
    }, []);

    function clearSuccessAndErrorMessages() {
        setNameError(null);
        setClientSlugError(null);
        setClientEditError(null);
        setSuccessMessage(null);
    }

    function validateAll() {
        if (!validateEmptyField(name)) {
            setNameError("Please fill this field");
            return false;
        }
        if (!validateClientSlug(clientSlug)) {
            setClientSlugError(
                "Please enter a valid client_slug like greenville_mi"
            );
            return false;
        }
        return true;
    }

    const handleClientEdit = async (e) => {
        e.preventDefault();

        clearSuccessAndErrorMessages();

        if (validateAll()) {
            let updatedClientObject = {};
            if (name !== client.name) updatedClientObject["name"] = name.trim();
            if (clientSlug !== client.client_slug)
                updatedClientObject["client_slug"] = clientSlug.trim();
            if (selectedTimezone.value !== client.timezone)
                updatedClientObject["timezone"] = selectedTimezone.value;
            try {
                const response = await axios.patch(
                    EDIT_CLIENT_BY_ID_URL + `/${id}`,
                    updatedClientObject,
                    {
                        headers: { Authorization: `Bearer ${jwt}` },
                    }
                );
                if (response.status === 200) {
                    setSuccessMessage("Client updated successfully");

                    const updatedClient = response.data.client;
                    // if the current user client is edited then update the current user redux object
                    if (updatedClient.id === currentUser.current_client) {
                        currentUser.current_client_slug =
                            updatedClient.client_slug;
                        currentUser.current_client_timezone =
                            updatedClient.timezone;

                        dispatch(editCurrentUser(currentUser));
                    }
                }
            } catch (error) {
                if (error.response) {
                    setClientEditError(error.response.data.message);
                    if (error.response.data.errors.name)
                        setNameError(error.response.data.errors.name);
                    if (error.response.data.errors.client_slug)
                        setClientSlugError(
                            error.response.data.errors.client_slug
                        );
                }
            }
        }
    };

    return (
        <>
            {jwt === null ? (
                // if the user is not logged in then redirect to login
                <Redirect to={LOGIN_ROUTE} />
            ) : (
                <div className="container-lg">
                    <ProjectNavbar />
                    <div className="container-fluid">
                        <div className="page-header">
                            <h1 className="d-inline">Edit Client</h1>
                        </div>
                        <hr />
                        {clientEditError !== null ? (
                            <Alert
                                color="danger"
                                toggle={(e) => {
                                    e.preventDefault();
                                    setClientEditError(null);
                                }}
                            >
                                {clientEditError}
                            </Alert>
                        ) : null}
                        {successMessage !== null ? (
                            <Alert
                                color="success"
                                toggle={(e) => {
                                    e.preventDefault();
                                    setSuccessMessage(null);
                                }}
                            >
                                {successMessage}
                            </Alert>
                        ) : null}
                        {client !== null ? (
                            <Form onSubmit={handleClientEdit}>
                                <TextField
                                    label={LOCAL_NAME}
                                    placeholder={`Enter ${LOCAL_NAME}`}
                                    type="text"
                                    autofocus={true}
                                    value={name ?? client.name}
                                    onChange={(e) =>
                                        handleInputChange(e, LOCAL_NAME)
                                    }
                                />
                                {nameError !== null ? (
                                    <p className="text-danger">{nameError}</p>
                                ) : null}
                                <TextField
                                    label={LOCAL_CLIENT_SLUG}
                                    placeholder={`Enter ${LOCAL_CLIENT_SLUG}`}
                                    type="text"
                                    value={clientSlug ?? client.client_slug}
                                    onChange={(e) =>
                                        handleInputChange(e, LOCAL_CLIENT_SLUG)
                                    }
                                />
                                {clientSlugError !== null ? (
                                    <p className="text-danger">
                                        {clientSlugError}
                                    </p>
                                ) : null}
                                <TimezoneSelect
                                    className="mb-4"
                                    value={selectedTimezone}
                                    onChange={setSelectedTimezone}
                                    labelStyle={labelStyle}
                                />
                                <Button
                                    color="primary"
                                    type="submit"
                                    disabled={!client.is_active}
                                    title={
                                        !client.is_active
                                            ? "Please activate the client first!"
                                            : null
                                    }
                                    className="submit-button"
                                >
                                    Save Client
                                </Button>
                            </Form>
                        ) : null}
                    </div>
                </div>
            )}
        </>
    );
};

export default EditClient;
