import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import { useHistory } from "react-router-dom";
import { Button, Form } from "reactstrap";
import { CLIENTS_ROUTE, CREATE_CLIENT_URL, LOGIN_ROUTE } from "../../constants";
import { validateClientSlug, validateEmptyField } from "../utils/validate";
import ProjectNavbar from "../widgets/projectNavbar";
import TextField from "../widgets/textField";
import TimezoneSelect from "react-timezone-select";
import { capitalize } from "../utils/stringUtils";

const CreateClient = () => {
    const jwt = useSelector((state) => state.authReducer.jwt);
    const history = useHistory();

    const [name, setName] = useState(null);
    const [nameError, setNameError] = useState(null);
    const [clientSlug, setClientSlug] = useState(null);
    const [clientSlugError, setClientSlugError] = useState(null);
    const [createClientError, setCreateClientError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [selectedTimezone, setSelectedTimezone] = useState({
        value: "America/Los_Angeles",
        label: "(GMT-7:00) Pacific Time (PDT)",
        offset: -7,
        abbrev: "PDT",
        altName: "Pacific Daylight Time",
    });
    const [labelStyle, setLabelStyle] = useState("abbrev");

    const LOCAL_NAME = "Name";
    const LOCAL_CLIENT_SLUG = "Client Slug";

    function handleInputChange(e, type) {
        e.preventDefault();

        clearSuccessAndErrorMessages();
        if (type === LOCAL_NAME) {
            e.target.value = capitalize(e.target.value);
            setName(e.target.value);
        }
        if (type === LOCAL_CLIENT_SLUG) setClientSlug(e.target.value);
    }

    function clearSuccessAndErrorMessages() {
        setNameError(null);
        setClientSlugError(null);
        setCreateClientError(null);
        setSuccessMessage(null);
    }

    function validateAll() {
        if (!validateEmptyField(name)) {
            setNameError("Please fill this field");
            return false;
        }
        if (!validateClientSlug(clientSlug)) {
            setClientSlugError("Please provide a valid client slug");
            return false;
        }
        return true;
    }

    const handleCreateClient = async (e) => {
        e.preventDefault();

        clearSuccessAndErrorMessages();

        if (validateAll()) {
            let newClientObject = {};
            newClientObject["name"] = name.trim();
            newClientObject["client_slug"] = clientSlug.trim();
            newClientObject["timezone"] = selectedTimezone.value;
            try {
                const response = await axios.post(
                    CREATE_CLIENT_URL,
                    newClientObject,
                    {
                        headers: { Authorization: `Bearer ${jwt}` },
                    }
                );
                if (response.status === 201) {
                    setSuccessMessage("Client created successfully");
                    history.push(CLIENTS_ROUTE);
                }
            } catch (error) {
                if (error.response) {
                    setCreateClientError(error.response.data.message);
                    if (error.response.data.errors.name)
                        setNameError(error.response.data.errors.name);
                    if (error.response.data.errors.client_slug)
                        setClientSlugError(
                            error.response.data.errors.client_slug
                        );
                }
            }
        }
    };

    return (
        <>
            {jwt === null ? (
                // if the user is not logged in then redirect to login
                <Redirect to={LOGIN_ROUTE} />
            ) : (
                <div className="container-lg">
                    <ProjectNavbar />
                    <div className="container-fluid">
                        <div className="page-header">
                            <h1 className="d-inline">Create Client</h1>
                        </div>
                        <hr />
                        <Form onSubmit={handleCreateClient}>
                            <TextField
                                label={LOCAL_NAME}
                                placeholder={`Enter ${LOCAL_NAME}`}
                                type="text"
                                autofocus={true}
                                value={name ?? ""}
                                onChange={(e) =>
                                    handleInputChange(e, LOCAL_NAME)
                                }
                            />
                            {nameError !== null ? (
                                <p className="text-danger">{nameError}</p>
                            ) : null}
                            <TextField
                                label={LOCAL_CLIENT_SLUG}
                                placeholder={`Enter ${LOCAL_CLIENT_SLUG}`}
                                type="text"
                                value={clientSlug ?? ""}
                                onChange={(e) =>
                                    handleInputChange(e, LOCAL_CLIENT_SLUG)
                                }
                            />
                            {clientSlugError !== null ? (
                                <p className="text-danger">{clientSlugError}</p>
                            ) : null}
                            <TimezoneSelect
                                className="mb-4"
                                value={selectedTimezone}
                                onChange={setSelectedTimezone}
                                labelStyle={labelStyle}
                            />
                            {createClientError !== null ? (
                                <p className="text-danger">
                                    {createClientError}
                                </p>
                            ) : null}
                            {successMessage !== null ? (
                                <p className="text-success">{successMessage}</p>
                            ) : null}
                            <Button color="primary" type="submit">
                                Save Client
                            </Button>
                        </Form>
                    </div>
                </div>
            )}
        </>
    );
};

export default CreateClient;
