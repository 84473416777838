import axios from "axios";
import { DOWNLOAD_MEETING_FILES_URL } from "../../constants";

export const handleFileDownload = async (e, jwt, file) => {
    e.preventDefault();

    const response = await axios.get(
        DOWNLOAD_MEETING_FILES_URL.replace(":id", file.id),
        {
            headers: { Authorization: `Bearer ${jwt}` },
            responseType: "blob",
        }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", file.name);
    document.body.appendChild(link);
    link.click();
};
