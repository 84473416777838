import React, { useState } from "react";
import axios from "axios";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Button, Input } from "reactstrap";
import TextField from "../widgets/textField";
import {
    LOGIN_URL,
    LS_SESSION_JWT,
    LS_CURRENT_USER,
    FORGOT_PASSWORD_ROUTE,
    MEETINGS_ROUTE,
    COPYRIGHT_TEXT,
} from "./../../constants.js";
import { useSelector, useDispatch } from "react-redux";
import checkSessionExpired from "./../utils/checkSessionExpired";
import { validateEmail, validatePassword } from "../utils/validate";
import { addCurrentUser, setAuthJwt } from "./../../redux/auth/authActions";
import "./../../styles/login.css";
import login_bg from "./../../assets/login-bg.jpg";
import revize_logo from "./../../assets/revize-logo.svg";

function Login(props) {
    const isSessionExpired =
        props.location.state !== undefined
            ? props.location.state.isSessionExpired
            : false;
    const dispatch = useDispatch();
    const jwt = useSelector((state) => state.authReducer.jwt);
    const history = useHistory();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const [emailError, setEmailError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);
    const [loginError, setLoginError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    function handleEmailChange(e) {
        setLoginError(null);
        setEmail(e.target.value);
    }

    function handlePasswordChange(e) {
        setLoginError(null);
        setPassword(e.target.value);
    }

    function clearSuccessAndErrorMessages() {
        setEmailError(null);
        setPasswordError(null);
        setLoginError(null);
        setSuccessMessage(null);
    }

    function validateAll() {
        if (!validateEmail(email)) {
            setEmailError("Please provide a valid email");
            return false;
        }
        if (!validatePassword(password)) {
            setPasswordError("Please provide a valid password");
            return false;
        }
        return true;
    }

    function handleLogin(e) {
        e.preventDefault();

        clearSuccessAndErrorMessages();

        if (validateAll()) {
            axios
                .post(LOGIN_URL, {
                    email: email,
                    password: password,
                })
                .then((response) => {
                    if (response.status === 200) {
                        dispatch(setAuthJwt(response.data.token));
                        dispatch(addCurrentUser(response.data.user));
                        // reponse.data.expires_in is time in seconds
                        const ttl = response.data.expires_in * 1000; // time for expiry in milliseconds
                        const jwtToLocalStorage = {
                            value: response.data,
                            expiry: new Date().getTime() + ttl,
                        };
                        const currentUserToLocalStorage = {
                            value: response.data.user,
                            expiry: new Date().getTime() + ttl,
                        };
                        localStorage.setItem(
                            LS_SESSION_JWT,
                            JSON.stringify(jwtToLocalStorage)
                        );
                        localStorage.setItem(
                            LS_CURRENT_USER,
                            JSON.stringify(currentUserToLocalStorage)
                        );
                        setTimeout(() => {
                            history.push({ MEETINGS_ROUTE });
                        }, 1500);
                    } else {
                        setLoginError(response.data.message);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        setLoginError(error.response.data.message);
                    }
                });
        }
    }

    return (
        <>
            {jwt !== null && checkSessionExpired() === false ? (
                // if the admin is logged in and session is not expired then redirect to admin dashboard
                <Redirect to={MEETINGS_ROUTE} />
            ) : (
                <div className="row login-row justify-content-center">
                    <div className="col-lg-4 col-md-7 login-form">
                        {isSessionExpired ? (
                            <p className="text-danger">
                                Session Expired Login again
                            </p>
                        ) : null}
                        <img
                            src={revize_logo}
                            className="revize-logo"
                            alt="revize logo"
                        />
                        <div>
                            <p className="mt-4 fs-4 mx-5">
                                Content Management Made Simple.
                            </p>
                            <form
                                onSubmit={handleLogin}
                                className="mx-5 text-start"
                            >
                                <TextField
                                    label="Email"
                                    placeholder="Enter email"
                                    type="text"
                                    autofocus={true}
                                    onChange={handleEmailChange}
                                    value={email}
                                    size="lg"
                                />
                                {emailError !== null ? (
                                    <p className="text-danger">{emailError}</p>
                                ) : null}
                                <TextField
                                    label="Password"
                                    placeholder="Enter password"
                                    type={showPassword ? "text" : "password"}
                                    onChange={handlePasswordChange}
                                    value={password}
                                    size="lg"
                                />
                                <Input
                                    id="show-password"
                                    type="checkbox"
                                    className="me-2"
                                    checked={showPassword}
                                    onChange={() =>
                                        setShowPassword(!showPassword)
                                    }
                                />
                                <label htmlFor="show-password">
                                    Show Password
                                </label>
                                <Link
                                    to={FORGOT_PASSWORD_ROUTE}
                                    className="nounderline float-end"
                                >
                                    <p className="inline text-blue-600">
                                        Forgot Password?
                                    </p>
                                </Link>
                                {passwordError !== null ? (
                                    <p className="text-danger">
                                        {passwordError}
                                    </p>
                                ) : null}
                                {loginError !== null ? (
                                    <p className="text-danger">{loginError}</p>
                                ) : null}
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="mt-2"
                                    size="lg"
                                    block={true}
                                >
                                    Login
                                </Button>
                                {successMessage !== null ? (
                                    <p className="text-success text-center">
                                        {successMessage}
                                    </p>
                                ) : null}
                            </form>
                        </div>
                        <div className="copyright mt-5 pt-5 small text-black-50">
                            {COPYRIGHT_TEXT}
                        </div>
                    </div>
                    <div className="col-lg-8 d-none d-sm-none d-md-none d-lg-block">
                        <img
                            src={login_bg}
                            className="login-bg"
                            alt="login bg"
                        />
                    </div>
                </div>
            )}
        </>
    );
}

export default Login;
