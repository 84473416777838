import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Table, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from "reactstrap";
import {
    EDIT_CLIENT_ROUTE,
    DOWNLOAD_MEETING_PACKETS_URL,
    EDIT_GROUP_ROUTE,
    EDIT_MEETING_ROUTE,
    EDIT_TEMPLATE_ROUTE,
    EDIT_USER_ROUTE,
    PUBLIC_MEETING_ROUTE,
    VIEW_MEETING_ROUTE,
    VIEW_PUBLIC_MEETING_ROUTE,
    VIEW_TEMPLATE_ROUTE,
} from "../../constants";
import ActivateModal from "./activateModal";
import DeleteModal from "./deleteModal";
import "./../../styles/projectTable.css";
import { getDuration } from "./../utils/timeUtils";
import { editGroupByIndex } from "../../redux/group/groupActions";

const ProjectTable = (props) => {
    const { data, type } = props;
    const jwt = useSelector((state) => state.authReducer.jwt);
    const currentUser = useSelector((state) => state.authReducer.currentUser);
    const dispatch = useDispatch();

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showActivateModal, setShowActivateModal] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(null);

    const showEditButton = (user) => {
        if (currentUser.role === "USER") {
            if (currentUser.id === user.id) return true;
            return false;
        }
        return true;
    };
    const showDeleteButton = (user, type) => {
        if (currentUser.role === "USER") return false;
        else if (type === "users" && currentUser.id === user.id) return false;
        return true;
    };

    const showActivateButton = (item) => {
        if (currentUser.role === "SUPERUSER") {
            if (item.is_active === 0) return true;
        }
        return false;
    };

    return data.length !== 0 ? (
        <>
            {showDeleteModal ? (
                <DeleteModal
                    setShowDeleteModal={setShowDeleteModal}
                    index={currentIndex}
                    item={data[currentIndex]}
                    type={type}
                    jwt={jwt}
                    dispatch={dispatch}
                />
            ) : null}
            {showActivateModal ? (
                <ActivateModal
                    setShowActivateModal={setShowActivateModal}
                    item={data[currentIndex]}
                    type={type}
                    jwt={jwt}
                    dispatch={dispatch}
                />
            ) : null}
            <Table striped>
                <thead>
                    {type === "USERS" ? (
                        <tr>
                            <th>Name</th>
                            <th>Role</th>
                            {currentUser.role === "SUPERUSER" ? (
                                <th>Active</th>
                            ) : null}
                            <th>Actions</th>
                        </tr>
                    ) : type === "CLIENTS" ? (
                        <tr>
                            <th>Name</th>
                            <th>Active</th>
                            <th>Actions</th>
                        </tr>
                    ) : type === "GROUPS" || type === "PUBLIC GROUPS" ? (
                        <tr>
                            <th>Name</th>
                            {currentUser.role === "SUPERUSER" &&
                            type === "GROUPS" ? (
                                <th>Active</th>
                            ) : null}
                            <th>Actions</th>
                        </tr>
                    ) : type === "PUBLIC SUBSCRIPTIONS" ? (
                        <tr>
                            <th>Name</th>
                            <th>Group</th>
                            <th>Confirmed</th>
                            <th>Actions</th>
                        </tr>
                    ) : type === "TEMPLATES" ? (
                        <tr>
                            <th>Name</th>
                            {currentUser.role === "SUPERUSER" ? (
                                <th>Active</th>
                            ) : null}
                            <th>Actions</th>
                        </tr>
                    ) : (
                        <tr>
                            <th>Name</th>
                            <th>Date</th>
                            {currentUser.role === "SUPERUSER" &&
                            type === "MEETINGS" ? (
                                <th>Active</th>
                            ) : null}
                            <th>Actions</th>
                        </tr>
                    )}
                </thead>
                <tbody>
                    {type === "USERS"
                        ? data.map((user, index) => {
                              return (
                                  <tr key={user.id}>
                                      <td>
                                          {user.last_name +
                                              ", " +
                                              user.first_name}
                                      </td>
                                      <td>{user.role}</td>
                                      {currentUser.role === "SUPERUSER" ? (
                                          <td>
                                              {user.is_active ? "YES" : "NO"}
                                          </td>
                                      ) : null}
                                      <td>
                                          <div>
                                              {showEditButton(user) ? (
                                                  <Link
                                                      to={EDIT_USER_ROUTE.replace(
                                                          ":id",
                                                          user.id
                                                      )}
                                                      className="btn btn-outline-primary"
                                                  >
                                                      Edit
                                                  </Link>
                                              ) : null}
                                              {showDeleteButton(
                                                  user,
                                                  "users"
                                              ) ? (
                                                  <Button
                                                      color="danger"
                                                      className="ms-2"
                                                      outline
                                                      onClick={(e) => {
                                                          e.preventDefault();
                                                          setCurrentIndex(
                                                              index
                                                          );
                                                          setShowDeleteModal(
                                                              true
                                                          );
                                                      }}
                                                  >
                                                      Delete
                                                  </Button>
                                              ) : null}
                                              {showActivateButton(user) ? (
                                                  <Button
                                                      color="success"
                                                      className="ms-2"
                                                      outline
                                                      onClick={async (e) => {
                                                          e.preventDefault();
                                                          setCurrentIndex(
                                                              index
                                                          );
                                                          setShowActivateModal(
                                                              true
                                                          );
                                                      }}
                                                  >
                                                      Activate
                                                  </Button>
                                              ) : null}
                                          </div>
                                      </td>
                                  </tr>
                              );
                          })
                        : type === "CLIENTS"
                        ? data.map((client, index) => {
                              return (
                                  <tr key={client.id}>
                                      <td>{client.name}</td>
                                      <td>{client.is_active ? "YES" : "NO"}</td>
                                      <td>
                                          <div>
                                              {currentUser.role ===
                                              "SUPERUSER" ? (
                                                  <Link
                                                      to={EDIT_CLIENT_ROUTE.replace(
                                                          ":id",
                                                          client.id
                                                      )}
                                                      className="btn btn-outline-primary"
                                                  >
                                                      Edit
                                                  </Link>
                                              ) : null}
                                              {currentUser.role ===
                                              "SUPERUSER" ? (
                                                  <Button
                                                      color="danger"
                                                      className="ms-2"
                                                      outline
                                                      onClick={(e) => {
                                                          e.preventDefault();
                                                          setCurrentIndex(
                                                              index
                                                          );
                                                          setShowDeleteModal(
                                                              true
                                                          );
                                                      }}
                                                  >
                                                      Delete
                                                  </Button>
                                              ) : null}
                                              {showActivateButton(client) ? (
                                                  <Button
                                                      color="success"
                                                      className="ms-2"
                                                      outline
                                                      onClick={async (e) => {
                                                          e.preventDefault();
                                                          setCurrentIndex(
                                                              index
                                                          );
                                                          setShowActivateModal(
                                                              true
                                                          );
                                                      }}
                                                  >
                                                      Activate
                                                  </Button>
                                              ) : null}
                                              <Link
                                                  to={PUBLIC_MEETING_ROUTE.replace(
                                                      ":client_slug",
                                                      client.client_slug
                                                  )}
                                                  target="_blank"
                                                  className="btn btn-outline-info ms-2"
                                              >
                                                  Go to public page
                                              </Link>
                                          </div>
                                      </td>
                                  </tr>
                              );
                          })
                        : type === "GROUPS" || type === "PUBLIC GROUPS"
                        ? data.map((group, index) => {
                              return (
                                  <tr key={group.id}>
                                      <td>{group.name}</td>
                                      {currentUser.role === "SUPERUSER" &&
                                      type === "GROUPS" ? (
                                          <td>
                                              {group.is_active ? "YES" : "NO"}
                                          </td>
                                      ) : null}
                                      <td>
                                          {type === "GROUPS" ? (
                                              <div>
                                                  <Link
                                                      to={EDIT_GROUP_ROUTE.replace(
                                                          ":id",
                                                          group.id
                                                      )}
                                                      className="btn btn-outline-primary"
                                                  >
                                                      {currentUser.role ===
                                                      "USER"
                                                          ? "View"
                                                          : "Edit"}
                                                  </Link>
                                                  {showDeleteButton(
                                                      null,
                                                      "groups"
                                                  ) ? (
                                                      <Button
                                                          color="danger"
                                                          className="ms-2"
                                                          outline
                                                          onClick={(e) => {
                                                              e.preventDefault();
                                                              setCurrentIndex(
                                                                  index
                                                              );
                                                              setShowDeleteModal(
                                                                  true
                                                              );
                                                          }}
                                                      >
                                                          Delete
                                                      </Button>
                                                  ) : null}
                                                  {showActivateButton(group) ? (
                                                      <Button
                                                          color="success"
                                                          className="ms-2"
                                                          outline
                                                          onClick={async (
                                                              e
                                                          ) => {
                                                              e.preventDefault();
                                                              setCurrentIndex(
                                                                  index
                                                              );
                                                              setShowActivateModal(
                                                                  true
                                                              );
                                                          }}
                                                      >
                                                          Activate
                                                      </Button>
                                                  ) : null}
                                                  <Link
                                                      to={`${PUBLIC_MEETING_ROUTE.replace(
                                                          ":client_slug",
                                                          currentUser.current_client_slug
                                                      )}?group_id=${group.id}`}
                                                      target="_blank"
                                                      className="btn btn-outline-info ms-2"
                                                  >
                                                      Go to public page
                                                  </Link>
                                              </div>
                                          ) : //  if type is PUBLIC GROUPS then check if seleted or not
                                          group.is_selected ? (
                                              <Button
                                                  color="danger"
                                                  className="ms-2"
                                                  outline
                                                  onClick={async (e) => {
                                                      e.preventDefault();
                                                      group.is_selected = false;
                                                      dispatch(
                                                          editGroupByIndex(
                                                              group,
                                                              group.id
                                                          )
                                                      );
                                                  }}
                                              >
                                                  Remove
                                              </Button>
                                          ) : (
                                              <Button
                                                  color="success"
                                                  className="ms-2"
                                                  outline
                                                  onClick={async (e) => {
                                                      e.preventDefault();
                                                      group.is_selected = true;
                                                      dispatch(
                                                          editGroupByIndex(
                                                              group,
                                                              group.id
                                                          )
                                                      );
                                                  }}
                                              >
                                                  Add
                                              </Button>
                                          )}
                                      </td>
                                  </tr>
                              );
                          })
                        : type === "PUBLIC SUBSCRIPTIONS"
                        ? data.map((publicSubscription, index) => {
                              return (
                                  <tr key={publicSubscription.id}>
                                      <td>{publicSubscription.email}</td>
                                      <td>{publicSubscription.group_name}</td>
                                      <td>
                                          {publicSubscription.is_confirmed
                                              ? "YES"
                                              : "NO"}
                                      </td>
                                      <td>
                                          <div>
                                              <Button
                                                  color="danger"
                                                  className="ms-2"
                                                  outline
                                                  onClick={(e) => {
                                                      e.preventDefault();
                                                      setCurrentIndex(index);
                                                      setShowDeleteModal(true);
                                                  }}
                                              >
                                                  Unsubscribe
                                              </Button>
                                          </div>
                                      </td>
                                  </tr>
                              );
                          })
                        : type === "TEMPLATES"
                        ? data.map((template, index) => {
                              return (
                                  <tr key={template.id}>
                                      <td>{template.name}</td>
                                      {currentUser.role === "SUPERUSER" ? (
                                          <td>
                                              {template.is_active
                                                  ? "YES"
                                                  : "NO"}
                                          </td>
                                      ) : null}
                                      <td>
                                          <div>
                                              <Link
                                                  to={VIEW_TEMPLATE_ROUTE.replace(
                                                      ":id",
                                                      template.id
                                                  )}
                                                  className="btn btn-outline-primary"
                                              >
                                                  View
                                              </Link>
                                              <Link
                                                  to={EDIT_TEMPLATE_ROUTE.replace(
                                                      ":id",
                                                      template.id
                                                  )}
                                                  className="btn btn-outline-primary ms-2"
                                              >
                                                  Edit
                                              </Link>
                                              {showDeleteButton(
                                                  null,
                                                  "templates"
                                              ) ? (
                                                  <Button
                                                      color="danger"
                                                      className="ms-2"
                                                      outline
                                                      onClick={(e) => {
                                                          e.preventDefault();
                                                          setCurrentIndex(
                                                              index
                                                          );
                                                          setShowDeleteModal(
                                                              true
                                                          );
                                                      }}
                                                  >
                                                      Delete
                                                  </Button>
                                              ) : null}
                                              {showActivateButton(template) ? (
                                                  <Button
                                                      color="success"
                                                      className="ms-2"
                                                      outline
                                                      onClick={async (e) => {
                                                          e.preventDefault();
                                                          setCurrentIndex(
                                                              index
                                                          );
                                                          setShowActivateModal(
                                                              true
                                                          );
                                                      }}
                                                  >
                                                      Activate
                                                  </Button>
                                              ) : null}
                                          </div>
                                      </td>
                                  </tr>
                              );
                          })
                        : type === "PUBLIC MEETINGS"
                        ? data.map((meeting, index) => {
                              return (
                                  <tr key={meeting.id}>
                                      <td className="meeting-details">
                                          <p className="fw-bold">
                                              {meeting.title}
                                          </p>
                                          <p>Meeting Name: {meeting.name}</p>
                                          <p>
                                              <span className="fw-bold">
                                                  Duration:{" "}
                                              </span>
                                              {getDuration(
                                                  meeting.start_time,
                                                  meeting.end_time
                                              )}
                                          </p>
                                      </td>
                                      <td>
                                          <p>
                                              {new Date(
                                                  meeting.start_time
                                              ).toLocaleString("en-US", {
                                                  year: "numeric",
                                                  month: "short",
                                                  day: "numeric",
                                                  hour: "numeric",
                                                  minute: "numeric",
                                                  hour12: true,
                                              })}
                                              {" - "}
                                              {new Date(
                                                  meeting.end_time
                                              ).toLocaleString("en-US", {
                                                  year: "numeric",
                                                  month: "short",
                                                  day: "numeric",
                                                  hour: "numeric",
                                                  minute: "numeric",
                                                  hour12: true,
                                              })}
                                          </p>
                                      </td>
                                      <td>
                                          <div>
                                          <UncontrolledDropdown className="d-inline-block">
                                                <DropdownToggle color="outline-primary" caret>View</DropdownToggle>
                                                <DropdownMenu >

                                                <DropdownItem>
                                                    <Link
                                                    to={{
                                                        pathname: VIEW_PUBLIC_MEETING_ROUTE.replace(
                                                        ":id",
                                                        meeting.id),
                                                        state: {is_minutes: false}
                                                    }}
                                                    className="dropdown-item"
                                                >
                                                    View Agenda
                                                </Link>
                                                </DropdownItem>
                                                {meeting.is_minutes_finalized ? (
                                                    <DropdownItem>
                                                        <Link
                                                    to={{
                                                        pathname: VIEW_PUBLIC_MEETING_ROUTE.replace(
                                                        ":id",
                                                        meeting.id),
                                                        state: {is_minutes: true}
                                                    }}
                                                    className="dropdown-item"
                                                >
                                                    View Minutes
                                                </Link>
                                                    </DropdownItem>
                                                ): null}

                                                {meeting.is_finalized ? (
                                                    <DropdownItem>
                                                        <a
                                                            key={meeting.id}
                                                            href={DOWNLOAD_MEETING_PACKETS_URL.replace(
                                                                ":id",
                                                                meeting.id
                                                            )}
                                                            target="_blank"
                                                            className="d-block dropdown-item"
                                                        >

                                                            View Agenda Packet
                                                        </a>
                                                    </DropdownItem>
                                                ) : null}
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                          </div>
                                      </td>
                                  </tr>
                              );
                          })
                        : data.map((meeting, index) => {
                              return (
                                  <tr key={meeting.id}>
                                      <td className="meeting-details">
                                          <p className="fw-bold">
                                              {meeting.title}
                                          </p>
                                          <p>Meeting Name: {meeting.name}</p>
                                          <p>
                                              <span className="fw-bold">
                                                  Duration:{" "}
                                              </span>
                                              {getDuration(
                                                  meeting.start_time,
                                                  meeting.end_time
                                              )}
                                          </p>
                                      </td>
                                      <td>
                                          <p>
                                              {new Date(
                                                  meeting.start_time
                                              ).toLocaleString("en-US", {
                                                  year: "numeric",
                                                  month: "short",
                                                  day: "numeric",
                                                  hour: "numeric",
                                                  minute: "numeric",
                                                  hour12: true,
                                              })}
                                              {" - "}
                                              {new Date(
                                                  meeting.end_time
                                              ).toLocaleString("en-US", {
                                                  year: "numeric",
                                                  month: "short",
                                                  day: "numeric",
                                                  hour: "numeric",
                                                  minute: "numeric",
                                                  hour12: true,
                                              })}
                                          </p>
                                      </td>
                                      {currentUser.role === "SUPERUSER" ? (
                                          <td>
                                              {meeting.is_active ? "YES" : "NO"}
                                          </td>
                                      ) : null}
                                      <td>
                                          <div>
                                            <UncontrolledDropdown className="d-inline-block">
                                                <DropdownToggle color="outline-primary" caret>View</DropdownToggle>
                                                <DropdownMenu >

                                                <DropdownItem>
                                                    <Link
                                                    to={{
                                                        pathname: VIEW_MEETING_ROUTE.replace(
                                                        ":id",
                                                        meeting.id),
                                                        state: {is_minutes: false}
                                                    }}
                                                    className="dropdown-item"
                                                >
                                                    View Agenda
                                                </Link>
                                                </DropdownItem>
                                                {meeting.is_minutes_finalized ? (
                                                    <DropdownItem>
                                                        <Link
                                                    to={{
                                                        pathname: VIEW_MEETING_ROUTE.replace(
                                                        ":id",
                                                        meeting.id),
                                                        state: {is_minutes: true}
                                                    }}
                                                    className="dropdown-item"
                                                >
                                                    View Minutes
                                                </Link>
                                                    </DropdownItem>
                                                ): null}

                                                {meeting.is_finalized ? (
                                                    <DropdownItem>
                                                        <a
                                                            key={meeting.id}
                                                            href={DOWNLOAD_MEETING_PACKETS_URL.replace(
                                                                ":id",
                                                                meeting.id
                                                            )}
                                                            target="_blank"
                                                            className="d-block dropdown-item"
                                                        >

                                                            View Agenda Packet
                                                        </a>
                                                    </DropdownItem>
                                                ) : null}
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                              
                                              <Link
                                                  to={EDIT_MEETING_ROUTE.replace(
                                                      ":id",
                                                      meeting.id
                                                  )}
                                                  className="btn btn-outline-primary ms-2"
                                              >
                                                  Edit
                                              </Link>
                                              {showDeleteButton(
                                                  null,
                                                  "meetings"
                                              ) ? (
                                                  <Button
                                                      color="danger"
                                                      className="ms-2"
                                                      outline
                                                      onClick={(e) => {
                                                          e.preventDefault();
                                                          setCurrentIndex(
                                                              index
                                                          );
                                                          setShowDeleteModal(
                                                              true
                                                          );
                                                      }}
                                                  >
                                                      Delete
                                                  </Button>
                                              ) : null}
                                              {showActivateButton(meeting) ? (
                                                  <Button
                                                      color="success"
                                                      className="ms-2"
                                                      outline
                                                      onClick={async (e) => {
                                                          e.preventDefault();
                                                          setCurrentIndex(
                                                              index
                                                          );
                                                          setShowActivateModal(
                                                              true
                                                          );
                                                      }}
                                                  >
                                                      Activate
                                                  </Button>
                                              ) : null}
                                          </div>
                                      </td>
                                  </tr>
                              );
                          })}
                </tbody>
            </Table>
        </>
    ) : (
        <h4 className="mt-5">No data Available</h4>
    );
};

export default ProjectTable;
