import axios from "axios";
import { GET_MEETING_HISTORY_URL } from "../../constants";

export const getMeetingHistory = async (
    e,
    id,
    jwt,
    setMeetingHistory,
    setIsShowMeetingHistory
) => {
    e.preventDefault();

    const response = await axios.get(
        GET_MEETING_HISTORY_URL.replace(":id", id),
        {
            headers: { Authorization: `Bearer ${jwt}` },
        }
    );

    setMeetingHistory(response.data.meeting_history);
    setIsShowMeetingHistory(true);
};
