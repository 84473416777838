export const disableEditMeeting = (meeting) => {
    return (
        new Date(meeting.end_time).getTime() <= new Date().getTime() ||
        isMeetingFinalized(meeting)
    );
};

const isMeetingFinalized = (meeting) => {
    return meeting.is_finalized;
};
