// this function accepts a Date object
export const getUTCDateForServer = (date) => {
    // return format is like 2022-03-02 (yyyy-mm-dd)

    // JavaScript counts months from 0 to 11
    // the month value sent here was reduced by 1(12 for December was sent as 11)
    // so that date.getUTCMonth() gives us the correct output
    // now which sending it back to the PHP server we need to increase it again by 1
    // therefore 11(December for JS) will be sent as 12(December for PHP)
    // that's why we have date.getUTCMonth() + 1 in the return statement

    // padStart() is used here to convert month and date into desired format of 2 digits i.e., something like 2022-1-8 is converted to 2022-01-08
    return `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getUTCDate().toString().padStart(2, "0")}`;
};

// this function accepts a Date object
export const getUTCTimeForServer = (date) => {
    // return format is like 04:30 (hh:mm)
    // padStart() is used here to convert minute and second into desired format of 2 digits i.e., something like 4:5 is converted to 04:05
    return `${date.getUTCHours().toString().padStart(2, "0")}:${date
        .getUTCMinutes()
        .toString()
        .padStart(2, "0")}`;
};

// this function accepts a Date object
export const getUTCDateTimeForServer = (date) => {
    // return format is like 2022-03-02 04:30 (yyyy-mm-dd hh:mm)
    return `${getUTCDateForServer(date)} ${getUTCTimeForServer(date)}`;
};

// this function accepts utcTime as a Date object and timezone as a timezone string(like, "America/Los_Angeles")
export const getLocalTimeFromUTC = (utcTime, tzString) => {
    return new Date(
        (typeof utcTime === "string"
            ? new Date(utcTime)
            : utcTime
        ).toLocaleString("en-US", { timeZone: tzString })
    );
};
