import axios from "axios";
import { DELETE_GROUP_BY_ID_URL } from "../../constants";
import {
    editGroupByIndex,
    removeGroupByIndex,
} from "../../redux/group/groupActions";

const handleDeleteGroup = async (
    index,
    group,
    jwt,
    dispatch,
    isHardDelete = false
) => {
    try {
        const response = await axios.delete(
            DELETE_GROUP_BY_ID_URL +
                `/${group.id}${isHardDelete ? "?delete=hard" : ""}`,
            {
                headers: { Authorization: `Bearer ${jwt}` },
            }
        );

        if (response.status === 204) {
            if (isHardDelete) dispatch(removeGroupByIndex(index));
            else {
                group["is_active"] = 0;
                const updatedGroup = group;
                dispatch(editGroupByIndex(updatedGroup, updatedGroup.id));
            }
            return true;
        }
        return false;
    } catch (error) {
        return false;
    }
};
export default handleDeleteGroup;
