import { useState } from "react";
import { Alert, Button, Col, FormGroup, Input, Label, Row, Collapse, CardBody, Card } from "reactstrap";
import { disableEditMeeting } from "../utils/disableEditMeeting";
import TextField from "./textField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faCog } from "@fortawesome/free-solid-svg-icons";
import { BASE_REACT_ROUTE, VIEW_MEETING_ROUTE } from "../../constants";

const MeetingDetails = (props) => {
    const {
        currentUser,
        meeting,
        meetingSettings,
        handleInputChange,
        name,
        nameError,
        title,
        titleError,
        date,
        dateError,
        startTime,
        startTimeError,
        endTime,
        endTimeError,
        groups,
        group,
        isMeetingFinalized,
        isMinutesFinalized,
        topicNumbering
    } = props;

    const LOCAL_NAME = "Name";
    const LOCAL_NAME_PACKET = "Show Name On Packet";
    const LOCAL_TITLE = "Title";
    const LOCAL_TITLE_PACKET = "Show Title On Packet";
    const LOCAL_DATE = "Date";
    const LOCAL_DATE_PACKET = "Show Date On Packet";
    const LOCAL_START_TIME = "Start time";
    const LOCAL_END_TIME = "End time";
    const LOCAL_GROUP = "Group";
    const LOCAL_MEETING_FINALIZED = "Meeting Finalized";
    const LOCAL_MINUTES_FINALIZED = "Minutes Finalized";
    const LOCAL_TOPIC_NUMBERING = "Topic Numbering";

    const [copyAlert, setCopyAlert] = useState(false);
    const [settingsOpen, setSettingsOpen] = useState(false);

    const toggleSettings = () => setSettingsOpen(!settingsOpen)

    const isMinutesDisabled = () => {
        return meeting.is_minutes_finalized || !meeting.is_finalized;
    };

    return (
        <div className="border p-2">
            <Alert color="primary">
                This section is editable only by ADMIN and SUPERUSER.
            </Alert>
            {copyAlert ? (
                <Alert
                    color="info"
                    toggle={(e) => {
                        e.preventDefault();
                        setCopyAlert(false);
                    }}
                >
                    Meeting link copied to clipboard!
                </Alert>
            ) : null}
            <Button
                color="primary"
                type="submit"
                className="float-end"
            >
                Save Meeting
            </Button>
            <Row>
                <Col>
                    <Button type="button" color="link" onClick={toggleSettings}>
                        <FontAwesomeIcon
                            icon={faCog}
                            color="grey"
                            size="2x"
                            title="Show meeting settings"
                        />
                    </Button>
                </Col>
            </Row>
            <Row>
                <Collapse isOpen={settingsOpen}>
                    <Card>
                        <CardBody>
                            <h5>Set meeting fields to show in Agenda Packet:</h5>
                            <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                                <label><input type="checkbox" onChange={(e) => handleInputChange(e, LOCAL_NAME_PACKET)} checked={meetingSettings.show_name_in_packet}/> Show Name in Agenda Packet</label>
                                <label><input type="checkbox" onChange={(e) => handleInputChange(e, LOCAL_TITLE_PACKET)} checked={meetingSettings.show_title_in_packet}/> Show Title in Agenda Packet</label>
                                <label><input type="checkbox" onChange={(e) => handleInputChange(e, LOCAL_DATE_PACKET)} checked={meetingSettings.show_date_in_packet}/> Show Date/Time in Agenda Packet</label>
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>
            </Row>
            <Row>
                <Col>
                    <TextField
                        label={LOCAL_NAME}
                        placeholder={`Enter ${LOCAL_NAME}`}
                        type="text"
                        autofocus={true}
                        value={name ?? ""}
                        disabled={
                            currentUser.role === "USER" ||
                            disableEditMeeting(meeting)
                        }
                        onChange={(e) => handleInputChange(e, LOCAL_NAME)}
                    />
                    {nameError !== null ? (
                        <p className="text-danger">{nameError}</p>
                    ) : null}
                </Col>
                <Col>
                    <TextField
                        label={LOCAL_TITLE}
                        placeholder={`Enter ${LOCAL_TITLE}`}
                        type="text"
                        value={title ?? ""}
                        disabled={
                            currentUser.role === "USER" ||
                            disableEditMeeting(meeting)
                        }
                        onChange={(e) => handleInputChange(e, LOCAL_TITLE)}
                    />
                    {titleError !== null ? (
                        <p className="text-danger">{titleError}</p>
                    ) : null}
                </Col>
                <Row>
                    <Col>
                        <FormGroup floating className="w-100">
                            <Input
                                id={LOCAL_TOPIC_NUMBERING}
                                name={LOCAL_TOPIC_NUMBERING}
                                type="select"
                                className="mb-2"
                                value={topicNumbering ?? "None"}
                                disabled={
                                    currentUser.role === "USER" ||
                                    disableEditMeeting(meeting)
                                }
                                onChange={(e) =>
                                    handleInputChange(
                                        e,
                                        LOCAL_TOPIC_NUMBERING
                                    )
                                }
                            >
                                {["None", "A", "1", "I"].map((element) => {
                                    return (
                                        <option key={element}>{element}</option>
                                    );
                                })}
                            </Input>
                            <Label htmlFor={LOCAL_TOPIC_NUMBERING}>
                                {LOCAL_TOPIC_NUMBERING}
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup floating className="w-100">
                            <Input
                                id={LOCAL_MEETING_FINALIZED}
                                name={LOCAL_MEETING_FINALIZED}
                                type="select"
                                className="mb-2"
                                value={isMeetingFinalized ? "YES" : "NO"}
                                disabled={meeting.is_finalized}
                                onChange={(e) =>
                                    handleInputChange(
                                        e,
                                        LOCAL_MEETING_FINALIZED
                                    )
                                }
                            >
                                {["YES", "NO"].map((element) => {
                                    return (
                                        <option key={element}>{element}</option>
                                    );
                                })}
                            </Input>
                            <Label htmlFor={LOCAL_MEETING_FINALIZED}>
                                {LOCAL_MEETING_FINALIZED}
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col>
                        <div className="d-flex">
                            <FormGroup floating className="w-100">
                                <Input
                                    id={LOCAL_MINUTES_FINALIZED}
                                    name={LOCAL_MINUTES_FINALIZED}
                                    type="select"
                                    className="me-2"
                                    value={isMinutesFinalized ? "YES" : "NO"}
                                    disabled={isMinutesDisabled()}
                                    title={
                                        isMinutesDisabled()
                                            ? "You cannot finalize the minutes before the meeting is finalized!"
                                            : ""
                                    }
                                    onChange={(e) =>
                                        handleInputChange(
                                            e,
                                            LOCAL_MINUTES_FINALIZED
                                        )
                                    }
                                >
                                    {["YES", "NO"].map((element) => {
                                        return (
                                            <option key={element}>
                                                {element}
                                            </option>
                                        );
                                    })}
                                </Input>
                                <Label htmlFor={LOCAL_MINUTES_FINALIZED}>
                                    {LOCAL_MINUTES_FINALIZED}
                                </Label>
                            </FormGroup>
                            {meeting.is_finalized ? (
                                <Button
                                    className="ms-3"
                                    color="transparent"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigator.clipboard.writeText(
                                            BASE_REACT_ROUTE +
                                            VIEW_MEETING_ROUTE.replace(
                                                ":id",
                                                meeting.id
                                            )
                                        );
                                        setCopyAlert(true);
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faCopy}
                                        color="grey"
                                        size="2x"
                                        title="Copy meeting link"
                                    />
                                </Button>
                            ) : null}
                        </div>
                    </Col>
                </Row>
            </Row>
            <Row>
                <Col>
                    <FormGroup floating>
                        <Input
                            id={LOCAL_DATE}
                            name={LOCAL_DATE}
                            placeholder="date placeholder"
                            type="date"
                            value={date ?? ""}
                            disabled={
                                currentUser.role === "USER" ||
                                disableEditMeeting(meeting)
                            }
                            onChange={(e) => handleInputChange(e, LOCAL_DATE)}
                        />
                        <Label>{LOCAL_DATE}</Label>
                    </FormGroup>
                    {dateError !== null ? (
                        <p className="text-danger">{dateError}</p>
                    ) : null}
                </Col>
                <Col>
                    <FormGroup floating>
                        <Input
                            id={LOCAL_START_TIME}
                            name={LOCAL_END_TIME}
                            type="time"
                            value={startTime ?? ""}
                            disabled={
                                currentUser.role === "USER" ||
                                disableEditMeeting(meeting)
                            }
                            onChange={(e) =>
                                handleInputChange(e, LOCAL_START_TIME)
                            }
                        />
                        <Label>{LOCAL_START_TIME}</Label>
                    </FormGroup>
                    {startTimeError !== null ? (
                        <p className="text-danger">{startTimeError}</p>
                    ) : null}
                </Col>
                <Col>
                    <FormGroup floating>
                        <Input
                            id={LOCAL_END_TIME}
                            name={LOCAL_END_TIME}
                            type="time"
                            value={endTime ?? ""}
                            disabled={
                                currentUser.role === "USER" ||
                                disableEditMeeting(meeting)
                            }
                            onChange={(e) =>
                                handleInputChange(e, LOCAL_END_TIME)
                            }
                        />
                        <Label>{LOCAL_END_TIME}</Label>
                    </FormGroup>
                    {endTimeError !== null ? (
                        <p className="text-danger">{endTimeError}</p>
                    ) : null}
                </Col>
                {groups ? (
                    <Col>
                        <FormGroup floating>
                            <Input
                                id={LOCAL_GROUP}
                                name={LOCAL_GROUP}
                                type="select"
                                className="mb-2"
                                value={group.name}
                                disabled={
                                    currentUser.role === "USER" ||
                                    disableEditMeeting(meeting)
                                }
                                onChange={(e) =>
                                    handleInputChange(e, LOCAL_GROUP)
                                }
                            >
                                {groups.map((groupElement) => {
                                    return (
                                        <option key={groupElement.id}>
                                            {groupElement.name}
                                        </option>
                                    );
                                })}
                            </Input>
                            <Label htmlFor={LOCAL_GROUP}>{LOCAL_GROUP}</Label>
                        </FormGroup>
                    </Col>
                ) : null}
            </Row>
        </div>
    );
};

export default MeetingDetails;
