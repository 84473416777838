import React, { useState } from "react";
import axios from "axios";
import { Link, Redirect } from "react-router-dom";
import { Button } from "reactstrap";
import TextField from "../widgets/textField";
import {
    LOGIN_ROUTE,
    FORGOT_PASSWORD_URL,
    MEETINGS_ROUTE,
} from "../../constants.js";
import { useSelector } from "react-redux";
import checkSessionExpired from "../utils/checkSessionExpired";
import { validateEmail } from "../utils/validate";
import revize_logo from "../../assets/revize-logo.svg";

function ForgotPassword() {
    const jwt = useSelector((state) => state.authReducer.jwt);

    const [email, setEmail] = useState("");

    const [emailError, setEmailError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    function handleEmailChange(e) {
        setEmail(e.target.value);
    }

    function clearSuccessAndErrorMessages() {
        setEmailError(null);
        setSuccessMessage(null);
    }

    function validateAll() {
        if (!validateEmail(email)) {
            setEmailError("Please provide a valid email");
            return false;
        }
        return true;
    }

    function handleSendPasswordResetLink(e) {
        e.preventDefault();

        clearSuccessAndErrorMessages();

        if (validateAll()) {
            axios
                .post(FORGOT_PASSWORD_URL, {
                    email: email,
                })
                .then((response) => {
                    // show user same message for success and error
                    // else they can detect which users emails exist in the system
                    setSuccessMessage(
                        "You should receive an email for password reset"
                    );
                })
                .catch((error) => {
                    if (error.response) {
                        setSuccessMessage(
                            "You should receive an email for password reset"
                        );
                    }
                });
        }
    }

    return (
        <>
            {jwt !== null && checkSessionExpired() === false ? (
                // if the user is logged in and session is not expired then redirect to meetings dashboard
                <Redirect to={MEETINGS_ROUTE} />
            ) : (
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <img
                        src={revize_logo}
                        className="revize-logo"
                        alt="revize logo"
                    />
                    <p className="mt-4 fs-2">Forgot Password</p>
                    <div className="rounded-3 shadow p-4">
                        <form onSubmit={handleSendPasswordResetLink}>
                            <TextField
                                label="Email"
                                placeholder="Enter email"
                                type="text"
                                autofocus={true}
                                onChange={handleEmailChange}
                                value={email}
                                size="lg"
                            />
                            {emailError !== null ? (
                                <p className="text-danger">{emailError}</p>
                            ) : null}
                            {successMessage !== null ? (
                                <p className="text-success">{successMessage}</p>
                            ) : null}
                            <Button
                                type="submit"
                                color="primary"
                                className="mt-2 float-end"
                            >
                                Send password reset link
                            </Button>
                        </form>
                    </div>
                    <Link
                        to={LOGIN_ROUTE}
                        className="nounderline text-start mt-4"
                    >
                        <p className="text-blue-600">Back to Login</p>
                    </Link>
                </div>
            )}
        </>
    );
}

export default ForgotPassword;
