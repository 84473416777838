import axios from "axios";
import { DELETE_TEMPLATE_BY_ID_URL } from "../../constants";
import {
    editTemplateByIndex,
    removeTemplateByIndex,
} from "../../redux/template/templateActions";

const handleDeleteTemplate = async (
    index,
    template,
    jwt,
    dispatch,
    isHardDelete = false
) => {
    try {
        const response = await axios.delete(
            DELETE_TEMPLATE_BY_ID_URL +
                `/${template.id}${isHardDelete ? "?delete=hard" : ""}`,
            {
                headers: { Authorization: `Bearer ${jwt}` },
            }
        );

        if (response.status === 204) {
            if (isHardDelete) dispatch(removeTemplateByIndex(index));
            else {
                template["is_active"] = 0;
                const updatedTemplate = template;
                dispatch(
                    editTemplateByIndex(updatedTemplate, updatedTemplate.id)
                );
            }
            return true;
        }
        return false;
    } catch (error) {
        return false;
    }
};
export default handleDeleteTemplate;
