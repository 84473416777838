import axios from "axios";
import { PUBLIC_UNSUBSCRIPTIONS } from "../../constants";
import { removePublicSubscriptionByIndex } from "../../redux/publicSubscription/publicSubscriptionActions";

const handleUnsubscribePublicSubscription = async (
    index,
    publicSubscription,
    _,
    dispatch,
    __
) => {
    try {
        const response = await axios.post(
            `${PUBLIC_UNSUBSCRIPTIONS}?email=${publicSubscription.email}&group_id=${publicSubscription.group_id}`
        );

        if (response.status === 200) {
            dispatch(removePublicSubscriptionByIndex(index));
            return true;
        }
        return false;
    } catch (error) {
        return false;
    }
};
export default handleUnsubscribePublicSubscription;
