import axios from "axios";
import _ from "lodash";
import { GET_MEETING_BY_ID_URL } from "../../constants";

const CompareMeeting = async (id, jwt, originalMeeting) => {
    const response = await axios.get(GET_MEETING_BY_ID_URL + `/${id}`, {
        headers: { Authorization: `Bearer ${jwt}` },
    });
    const newMeeting = response.data.meeting;
    const isEqual = _.isEqual(originalMeeting, newMeeting);
    return isEqual;
};

export default CompareMeeting;
