export const getDuration = (startTime, endTime) => {
    const diff = new Date(endTime) - new Date(startTime);
    let minutes = diff / 60000;
    if (minutes > 60) {
        const hours = parseInt(minutes / 60);
        minutes = minutes - hours * 60;

        if (minutes > 5)
            return `${hours} ${
                hours === 1 ? "hour" : "hours"
            } ${minutes} minutes`;

        return `${hours} ${hours === 1 ? "hour" : "hours"}`;
    }
    return `${minutes} minutes`;
};
