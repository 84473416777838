import {
    ADD_ALL_MEETINGS,
    EDIT_MEETING_BY_INDEX,
    REMOVE_ALL_MEETINGS,
    REMOVE_MEETING_BY_INDEX,
} from "./meetingTypes.js";

export const addAllMeetings = (meetings = []) => {
    return {
        type: ADD_ALL_MEETINGS,
        payload: meetings,
    };
};

export const removeAllMeetings = () => {
    return {
        type: REMOVE_ALL_MEETINGS,
    };
};

export const editMeetingByIndex = (updatedMeeting = {}, id) => {
    return {
        type: EDIT_MEETING_BY_INDEX,
        payload: { updatedMeeting: updatedMeeting, id: id },
    };
};

export const removeMeetingByIndex = (index) => {
    return {
        type: REMOVE_MEETING_BY_INDEX,
        payload: index,
    };
};
