import React, { useState } from "react";
import axios from "axios";
import { Redirect, useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import TextField from "../widgets/textField";
import {
    PASSWORD_RESET_URL,
    LOGIN_ROUTE,
    MEETINGS_ROUTE,
} from "../../constants.js";
import { useSelector } from "react-redux";
import checkSessionExpired from "../utils/checkSessionExpired";
import { validateEmail, validatePassword } from "../utils/validate";

function ResetPassword() {
    const jwt = useSelector((state) => state.authReducer.jwt);
    const history = useHistory();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");

    const [emailError, setEmailError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);
    const [passwordConfirmationError, setPasswordConfirmationError] =
        useState(null);
    const [passwordResetError, setPasswordResetError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    function handleEmailChange(e) {
        setPasswordResetError(null);
        setEmail(e.target.value);
    }

    function handlePasswordChange(e) {
        setPasswordResetError(null);
        setPassword(e.target.value);
    }

    function handlePasswordConfirmationChange(e) {
        setPasswordConfirmationError(null);
        setPasswordConfirmation(e.target.value);
    }

    function clearSuccessAndErrorMessages() {
        setEmailError(null);
        setPasswordError(null);
        setPasswordResetError(null);
        setSuccessMessage(null);
    }

    function validateAll() {
        if (!validateEmail(email)) {
            setEmailError("Please provide a valid email!");
            return false;
        }
        if (!validatePassword(password)) {
            setPasswordError("Please provide a valid password!");
            return false;
        }
        if (password !== passwordConfirmation) {
            setPasswordConfirmationError(
                "Password and Password Confirmation must be identical!"
            );
            return false;
        }
        return true;
    }

    function handleResetPassword(e) {
        e.preventDefault();

        clearSuccessAndErrorMessages();

        if (validateAll()) {
            const url = window.location.href;
            const urlObject = new URL(url);
            const token = urlObject.searchParams.get("token");

            axios
                .post(PASSWORD_RESET_URL + `?token=${token}`, {
                    email: email,
                    password: password,
                    password_confirmation: passwordConfirmation,
                })
                .then((response) => {
                    if (response.status === 200) {
                        setSuccessMessage(
                            "Password reset successfully. Redirecting to login page."
                        );
                        setTimeout(() => {
                            history.push(LOGIN_ROUTE);
                        }, 1500);
                    } else {
                        setPasswordResetError(response.data.message);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        setPasswordResetError(error.response.data.message);
                    }
                });
        }
    }

    return (
        <>
            {jwt !== null && checkSessionExpired() === false ? (
                // if the admin is logged in and session is not expired then redirect to meetings dashboard
                <Redirect to={MEETINGS_ROUTE} />
            ) : (
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <p className="mt-4 fs-1">Revize Agenda Builder</p>
                    <div className="rounded-3 shadow p-4">
                        <form onSubmit={handleResetPassword}>
                            <TextField
                                label="Email"
                                placeholder="Enter email"
                                type="text"
                                autofocus={true}
                                value={email}
                                onChange={handleEmailChange}
                            />
                            {emailError !== null ? (
                                <p className="text-danger">{emailError}</p>
                            ) : null}
                            <TextField
                                label="Password"
                                placeholder="Enter new password"
                                type="password"
                                value={password}
                                onChange={handlePasswordChange}
                            />
                            {passwordError !== null ? (
                                <p className="text-danger">{passwordError}</p>
                            ) : null}
                            <TextField
                                label="Password Confirmation"
                                placeholder="Enter new password"
                                type="password"
                                value={passwordConfirmation}
                                onChange={handlePasswordConfirmationChange}
                            />
                            {passwordConfirmationError !== null ? (
                                <p className="text-danger">
                                    {passwordConfirmationError}
                                </p>
                            ) : null}
                            {passwordResetError !== null ? (
                                <p className="text-danger">
                                    {passwordResetError}
                                </p>
                            ) : null}
                            {successMessage !== null ? (
                                <p className="text-success">{successMessage}</p>
                            ) : null}
                            <Button
                                type="submit"
                                color="primary"
                                className="mt-2 float-end"
                            >
                                Reset Password
                            </Button>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
}

export default ResetPassword;
