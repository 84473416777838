import { Button } from "reactstrap";

const AddItemsPopup = (props) => {
    const {
        handleAddItem,
        addIndex,
        addPos,
        buttonSize,
        disabled = false,
    } = props;

    return (
        <>
            <Button
                color="primary"
                className="me-3"
                size={buttonSize}
                disabled={disabled}
                onClick={(e) => handleAddItem(e, "AGENDA", addIndex, addPos)}
            >
                Add Text Section
            </Button>
            <Button
                color="success"
                size={buttonSize}
                disabled={disabled}
                onClick={(e) => handleAddItem(e, "TOPIC", addIndex, addPos)}
            >
                Add Topic
            </Button>
        </>
    );
};

export default AddItemsPopup;
