import { useState } from "react";
import { Button, Form, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import handleFileChange from "../utils/handleFileChange";

function FileDescriptionModal(props) {
    const {
        setShowFileDescriptionModal,
        fileEvent,
        topicIndex,
        topic,
        isMinuteFile,
        meeting,
        setMeeting,
        minuteFilesToBeUploaded,
        setMinuteFilesToBeUploaded,
        filesToBeUploaded,
        setFilesToBeUploaded,
    } = props;
    const [description, setDescription] = useState("Default file description");

    return (
        <Modal isOpen={true} autoFocus={false}>
            <ModalHeader>Please describe the selected file</ModalHeader>
            <ModalBody>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleFileChange(
                            fileEvent,
                            description,
                            topicIndex,
                            topic,
                            isMinuteFile,
                            meeting,
                            setMeeting,
                            minuteFilesToBeUploaded,
                            setMinuteFilesToBeUploaded,
                            filesToBeUploaded,
                            setFilesToBeUploaded
                        );
                        setShowFileDescriptionModal(false);
                    }}
                >
                    <Input
                        value={description}
                        autoFocus={true}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <Button
                        type="submit"
                        className="mt-2 float-end"
                        color="success"
                    >
                        Submit
                    </Button>
                </Form>
            </ModalBody>
        </Modal>
    );
}

export default FileDescriptionModal;
