import axios from "axios";
import { DELETE_USER_BY_ID_URL } from "../../constants";
import {
    editUserByIndex,
    removeUserByIndex,
} from "../../redux/user/userActions";

const handleDeleteUser = async (
    index,
    user,
    jwt,
    dispatch,
    isHardDelete = false
) => {
    try {
        const response = await axios.delete(
            DELETE_USER_BY_ID_URL +
                `/${user.id}${isHardDelete ? "?delete=hard" : ""}`,
            {
                headers: { Authorization: `Bearer ${jwt}` },
            }
        );

        if (response.status === 204) {
            if (isHardDelete) dispatch(removeUserByIndex(index));
            else {
                user["is_active"] = 0;
                const updatedUser = user;
                dispatch(editUserByIndex(updatedUser, updatedUser.id));
            }
            return true;
        }
        return false;
    } catch (error) {
        return false;
    }
};
export default handleDeleteUser;
