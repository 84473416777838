import _ from "lodash";

const handleFileDelete = (
    e,
    topicIndex,
    file,
    meeting,
    setMeeting,
    update_items_id_type_list,
    setUpdateItemsIdTypeList
) => {
    e.preventDefault();

    const localMeeting = _.cloneDeep(meeting);
    if (
        !update_items_id_type_list.includes({
            id: localMeeting.items[topicIndex].id,
            type: localMeeting.items[topicIndex].type,
        })
    )
        setUpdateItemsIdTypeList([
            ...update_items_id_type_list,
            {
                id: localMeeting.items[topicIndex].id,
                type: localMeeting.items[topicIndex].type,
            },
        ]);

    if (file.is_minute_file)
        localMeeting.items[topicIndex].minute_files = localMeeting.items[
            topicIndex
        ].minute_files.filter((fileElement) => fileElement.id !== file.id);
    else
        localMeeting.items[topicIndex].files = localMeeting.items[
            topicIndex
        ].files.filter((fileElement) => fileElement.id !== file.id);

    if ("delete_files" in localMeeting.items[topicIndex] === false)
        localMeeting.items[topicIndex].delete_files = [];
    localMeeting.items[topicIndex].delete_files.push(file.id);
    setMeeting(localMeeting);
};

export default handleFileDelete;
