import axios from "axios";
import { ACTIVATE_TEMPLATE_BY_ID_URL } from "../../constants";
import { editTemplateByIndex } from "../../redux/template/templateActions";

const handleActivateTemplate = async (template, jwt, dispatch) => {
    try {
        const response = await axios.patch(
            ACTIVATE_TEMPLATE_BY_ID_URL.replace(":id", template.id),
            null, // sending null here as we don't want to send any data
            {
                headers: { Authorization: `Bearer ${jwt}` },
            }
        );

        if (response.status === 200) {
            const updatedTemplate = response.data.template;
            dispatch(editTemplateByIndex(updatedTemplate, updatedTemplate.id));

            return { success: true };
        }
        return { success: false, error: response };
    } catch (error) {
        return { success: false, error: error.response.data.error };
    }
};
export default handleActivateTemplate;
