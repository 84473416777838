import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import handleDeleteClient from "../utils/deleteClient";
import handleDeleteGroup from "../utils/deleteGroup";
import handleDeleteMeeting from "../utils/deleteMeeting";
import handleDeleteTemplate from "../utils/deleteTemplate";
import handleDeleteUser from "../utils/deleteUser";
import handleUnsubscribePublicSubscription from "../utils/unsubscribePublicSubscription";
import "../../styles/deleteModal.css";

function DeleteModal(props) {
    const { setShowDeleteModal, index, item, type, jwt, dispatch } = props;

    return (
        <Modal isOpen={true} autoFocus={false}>
            <ModalHeader className="delete-modal-header">
                Do you want to delete the {type.toLowerCase().slice(0, -1)}{" "}
                permanently?
                {type !== "PUBLIC SUBSCRIPTIONS"
                    ? `(try deactivate instead)`
                    : null}
            </ModalHeader>
            <ModalBody>
                {type !== "PUBLIC SUBSCRIPTIONS" ? (
                    <Button
                        type="submit"
                        color="danger"
                        className="me-2"
                        onClick={async (e) => {
                            e.preventDefault();
                            if (type === "USERS")
                                await handleDeleteUser(
                                    index,
                                    item,
                                    jwt,
                                    dispatch,
                                    true
                                );
                            else if (type === "CLIENTS")
                                await handleDeleteClient(
                                    index,
                                    item,
                                    jwt,
                                    dispatch,
                                    true
                                );
                            else if (type === "GROUPS")
                                await handleDeleteGroup(
                                    index,
                                    item,
                                    jwt,
                                    dispatch,
                                    true
                                );
                            else if (type === "TEMPLATES")
                                await handleDeleteTemplate(
                                    index,
                                    item,
                                    jwt,
                                    dispatch,
                                    true
                                );
                            else
                                await handleDeleteMeeting(
                                    index,
                                    item,
                                    jwt,
                                    dispatch,
                                    true
                                );
                            setShowDeleteModal(false);
                        }}
                    >
                        Delete
                    </Button>
                ) : null}
                <Button
                    type="submit"
                    color="primary"
                    onClick={async (e) => {
                        e.preventDefault();
                        if (type === "USERS")
                            await handleDeleteUser(
                                index,
                                item,
                                jwt,
                                dispatch,
                                false
                            );
                        else if (type === "CLIENTS")
                            await handleDeleteClient(
                                index,
                                item,
                                jwt,
                                dispatch,
                                false
                            );
                        else if (type === "GROUPS")
                            await handleDeleteGroup(
                                index,
                                item,
                                jwt,
                                dispatch,
                                false
                            );
                        else if (type === "PUBLIC SUBSCRIPTIONS")
                            await handleUnsubscribePublicSubscription(
                                index,
                                item,
                                jwt,
                                dispatch,
                                true
                            );
                        else if (type === "TEMPLATES")
                            await handleDeleteTemplate(
                                index,
                                item,
                                jwt,
                                dispatch,
                                false
                            );
                        else
                            await handleDeleteMeeting(
                                index,
                                item,
                                jwt,
                                dispatch,
                                false
                            );
                        setShowDeleteModal(false);
                    }}
                >
                    {type === "PUBLIC SUBSCRIPTIONS"
                        ? `Unsubscribe`
                        : `Deactivate`}
                </Button>
                <Button
                    color="danger"
                    className="float-end"
                    onClick={(e) => {
                        e.preventDefault();
                        setShowDeleteModal(false);
                    }}
                >
                    Close
                </Button>
            </ModalBody>
        </Modal>
    );
}

export default DeleteModal;
