import axios from "axios";
import _ from "lodash";
import {
    EDIT_MEETING_BY_ID_URL,
    EDIT_MEETING_MINUTES_BY_ID_URL,
    UPLOAD_MEETING_FILES_URL,
} from "../../constants";
import { getUTCDateForServer, getUTCDateTimeForServer } from "./timeConverts";

const handleEditMeeting = async (
    e,
    id,
    jwt,
    clearSuccessAndErrorMessages,
    validateAll,
    meeting,
    meetingSettings,
    name,
    title,
    date,
    group,
    startTime,
    endTime,
    isMeetingFinalized,
    topicNumbering,
    checkForUpdateItems,
    remove_items,
    update_items,
    disableEditMeeting,
    setSuccessMessage,
    minuteFilesToBeUploaded,
    filesToBeUploaded,
    setEditMeetingError,
    setNameError,
    setMeeting,
    setOriginalMeeting,
    originalMeeting,
    isMinutesFinalized,
    setRemoveItems,
    setUpdateItemsIdTypeList
) => {
    e.preventDefault();

    clearSuccessAndErrorMessages();

    if (validateAll()) {
        let updatedMeetingObject = {};
        if (name !== meeting.name) updatedMeetingObject["name"] = name.trim();
        if (title !== meeting.title)
            updatedMeetingObject["title"] = title.trim();
        if (group.id !== meeting.group_id)
            updatedMeetingObject["group_id"] = group.id;
        if (topicNumbering !== meeting.topic_numbering)
            updatedMeetingObject["topic_numbering"] = topicNumbering;

        const year = date.split("-")[0];
        const month = date.split("-")[1];
        const day = date.split("-")[2];
        if (date !== meeting.date)
            // updatedMeetingObject["date"] = date;
            updatedMeetingObject["date"] = getUTCDateForServer(
                new Date(
                    year,
                    month - 1, // JavaScript counts months from 0 to 11
                    day,
                    startTime.split(":")[0],
                    startTime.split(":")[1]
                )
            );
        if (startTime !== meeting.start_time.split(" ")[1])
            // updatedMeetingObject["start_time"] = `${date} ${startTime}:00`;
            updatedMeetingObject["start_time"] = getUTCDateTimeForServer(
                new Date(
                    year,
                    month - 1, // JavaScript counts months from 0 to 11
                    day,
                    startTime.split(":")[0],
                    startTime.split(":")[1]
                )
            );
        if (endTime !== meeting.end_time.split(" ")[1])
            // updatedMeetingObject["end_time"] = `${date} ${endTime}:00`;
            updatedMeetingObject["end_time"] = getUTCDateTimeForServer(
                new Date(
                    year,
                    month - 1, // JavaScript counts months from 0 to 11
                    day,
                    endTime.split(":")[0],
                    endTime.split(":")[1]
                )
            );
        if (isMeetingFinalized !== meeting.is_finalized)
            updatedMeetingObject["is_finalized"] = isMeetingFinalized;
        if (isMinutesFinalized !== meeting.is_minutes_finalized)
            updatedMeetingObject["is_minutes_finalized"] = isMinutesFinalized;

        if (meetingSettings.show_name_in_packet !== meeting.show_name_in_packet)
            updatedMeetingObject["show_name_in_packet"] = meetingSettings.show_name_in_packet;
        if (meetingSettings.show_title_in_packet !== meeting.show_title_in_packet)
            updatedMeetingObject["show_title_in_packet"] = meetingSettings.show_title_in_packet;
        if (meetingSettings.show_date_in_packet !== meeting.show_date_in_packet)
            updatedMeetingObject["show_date_in_packet"] = meetingSettings.show_date_in_packet;

        let add_items = [];
        meeting.items.forEach((item) => {
            if (item.id === null) add_items = [...add_items, _.cloneDeep(item)];
        });
        checkForUpdateItems();

        if (add_items.length !== 0)
            updatedMeetingObject["add_items"] = add_items;
        if (remove_items.length !== 0)
            updatedMeetingObject["remove_items"] = remove_items;
        console.log(`handleEditMeeting update_items = ${JSON.stringify(update_items)}`);
        if (update_items.length !== 0)
            updatedMeetingObject["update_items"] = update_items;

        console.log(`final updatedMeetingObject = ${JSON.stringify(updatedMeetingObject)}`);

        try {
            const response = await axios.patch(
                `${disableEditMeeting(originalMeeting)
                    ? EDIT_MEETING_MINUTES_BY_ID_URL.replace(":id", id)
                    : EDIT_MEETING_BY_ID_URL + `/${id}`
                }`,
                updatedMeetingObject,
                {
                    headers: { Authorization: `Bearer ${jwt}` },
                }
            );
            if (response.status === 200) {
                setSuccessMessage(
                    `Meeting ${disableEditMeeting(meeting) ? "minutes" : ""
                    } updated successfully`
                );

                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                });
                setMeeting(response.data.meeting);
                setOriginalMeeting(response.data.meeting);

                setRemoveItems([]);
                update_items = [];
                setUpdateItemsIdTypeList([]);

                if (disableEditMeeting(meeting)) {
                    minuteFilesToBeUploaded.forEach(async (file) => {
                        let form_data = new FormData();
                        form_data.append("topic_id", file.topic_id);
                        form_data.append("is_minute_file", file.is_minute_file);
                        form_data.append("files", file.file);
                        form_data.append("description", file.file_description);

                        const filesResponse = await axios.post(
                            UPLOAD_MEETING_FILES_URL,
                            form_data,
                            {
                                headers: {
                                    "Content-type": "multipart/form-data",
                                    Authorization: `Bearer ${jwt}`,
                                },
                            }
                        );

                        if (filesResponse.status === 200)
                            setSuccessMessage(
                                "Meeting minutes files uploaded successfully"
                            );
                    });
                } else {
                    console.log(
                        `filesToBeUploaded = ${JSON.stringify(
                            filesToBeUploaded
                        )}`
                    );
                    filesToBeUploaded.forEach(async (file) => {
                        if (file.topic_id === null) {
                            console.log(
                                `file.topic_id is null ${JSON.stringify(file)}`
                            );
                            response.data.meeting.items.forEach((item) => {
                                console.log(`item = ${JSON.stringify(item)}`);
                                if (item.name === file.topic.name) {
                                    file.topic_id = item.id;
                                    return;
                                }
                            });
                        }

                        let form_data = new FormData();
                        form_data.append("topic_id", file.topic_id);
                        form_data.append("is_minute_file", file.is_minute_file);
                        form_data.append("files", file.file);
                        form_data.append("description", file.file_description);

                        const filesResponse = await axios.post(
                            UPLOAD_MEETING_FILES_URL,
                            form_data,
                            {
                                headers: {
                                    "Content-type": "multipart/form-data",
                                    Authorization: `Bearer ${jwt}`,
                                },
                            }
                        );

                        if (filesResponse.status === 200)
                            setSuccessMessage(
                                "Meeting files uploaded successfully"
                            );
                    });
                }
            }
        } catch (error) {
            if (error.response) {
                setEditMeetingError(error.response.data.error);
                if (error.response.data.errors)
                    if (error.response.data.errors.name)
                        setNameError(error.response.data.errors.name);
            }
        }
    }
};

export default handleEditMeeting;
