import axios from "axios";
import { DELETE_CLIENT_BY_ID_URL } from "../../constants";
import {
    editClientByIndex,
    removeClientByIndex,
} from "../../redux/client/clientActions";

const handleDeleteClient = async (
    index,
    client,
    jwt,
    dispatch,
    isHardDelete = false
) => {
    try {
        const response = await axios.delete(
            DELETE_CLIENT_BY_ID_URL +
                `/${client.id}${isHardDelete ? "?delete=hard" : ""}`,
            {
                headers: { Authorization: `Bearer ${jwt}` },
            }
        );

        if (response.status === 204) {
            if (isHardDelete) dispatch(removeClientByIndex(index));
            else {
                client["is_active"] = 0;
                const updatedClient = client;
                dispatch(editClientByIndex(updatedClient, updatedClient.id));
            }
            return true;
        }
        return false;
    } catch (error) {
        return false;
    }
};
export default handleDeleteClient;
