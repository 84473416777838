import { useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import handleActivateClient from "../utils/activateClient";
import handleActivateGroup from "../utils/activateGroup";
import handleActivateMeeting from "../utils/activateMeeting";
import handleActivateTemplate from "../utils/activateTemplate";
import handleActivateUser from "../utils/activateUser";
import "../../styles/activateModal.css";

function ActivateModal(props) {
    const { setShowActivateModal, item, type, jwt, dispatch } = props;
    const [error, setError] = useState(null);

    return (
        <Modal isOpen={true} autoFocus={false}>
            <ModalHeader className="activate-modal-header">
                Do you want to activate the {type.toLowerCase().slice(0, -1)}?
            </ModalHeader>
            <ModalBody>
                {error !== null ? <p className="text-danger">{error}</p> : null}
                <Button
                    type="submit"
                    color="success"
                    onClick={async (e) => {
                        e.preventDefault();
                        let result = null;
                        if (type === "USERS")
                            result = await handleActivateUser(
                                item,
                                jwt,
                                dispatch
                            );
                        else if (type === "CLIENTS")
                            result = await handleActivateClient(
                                item,
                                jwt,
                                dispatch
                            );
                        else if (type === "GROUPS") {
                            result = await handleActivateGroup(
                                item,
                                jwt,
                                dispatch
                            );
                        } else if (type === "TEMPLATES") {
                            result = await handleActivateTemplate(
                                item,
                                jwt,
                                dispatch
                            );
                        } else {
                            result = await handleActivateMeeting(
                                item,
                                jwt,
                                dispatch
                            );
                        }
                        if (result.success) setShowActivateModal(false);
                        else setError(result.error);
                    }}
                >
                    Activate
                </Button>
                <Button
                    color="danger"
                    className="float-end"
                    onClick={(e) => {
                        e.preventDefault();
                        setShowActivateModal(false);
                    }}
                >
                    Close
                </Button>
            </ModalBody>
        </Modal>
    );
}

export default ActivateModal;
