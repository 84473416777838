import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router";
import {
    Alert,
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Table,
} from "reactstrap";
import {
    EDIT_USER_BY_ID_URL,
    GET_ALL_CLIENTS_URL,
    GET_ALL_GROUPS_URL,
    GET_USER_BY_ID_URL,
    LOGIN_ROUTE,
    USER_ROLES_ENUM,
} from "../../constants";
import { addCurrentUser } from "../../redux/auth/authActions";
import _ from "lodash";
import {
    validateEmail,
    validateEmptyField,
    validatePassword,
} from "../utils/validate";
import ProjectNavbar from "../widgets/projectNavbar";
import TextField from "../widgets/textField";
import "./../../styles/editUser.css";
import { capitalize } from "../utils/stringUtils";

const EditUser = () => {
    const jwt = useSelector((state) => state.authReducer.jwt);
    const currentUser = useSelector((state) => state.authReducer.currentUser);
    const { id } = useParams();
    const dispatch = useDispatch();

    const [user, setUser] = useState(null);

    const [firstname, setFirstname] = useState(null);
    const [firstnameError, setFirstnameError] = useState(null);
    const [lastname, setLastname] = useState(null);
    const [lastnameError, setLastnameError] = useState(null);
    const [role, setRole] = useState(null);
    const [current_client, setCurrentClient] = useState({
        name: null,
        id: null,
    }); // by-default add no client to the user
    const [email, setEmail] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState(null);
    const [userEditError, setUserEditError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const [clients, setClients] = useState([]);
    const [groups, setGroups] = useState([]);
    const [originalGroups, setOriginalGroups] = useState([]);

    const LOCAL_FIRSTNAME = "First Name";
    const LOCAL_LASTNAME = "Last Name";
    const LOCAL_ROLE = "Role";
    const LOCAL_CLIENT = "Client";
    const LOCAL_EMAIL = "Email";
    const LOCAL_PASSWORD = "Password";
    const LOCAL_CONFIRM_PASSWORD = "Confirm Password";

    function handleInputChange(e, type) {
        e.preventDefault();

        clearSuccessAndErrorMessages();
        if (type === LOCAL_FIRSTNAME) {
            e.target.value = capitalize(e.target.value);
            setFirstname(e.target.value);
        }
        if (type === LOCAL_LASTNAME) {
            e.target.value = capitalize(e.target.value);
            setLastname(e.target.value);
        }
        if (type === LOCAL_ROLE) setRole(e.target.value);
        if (type === LOCAL_CLIENT) {
            const matchedClient = getClientByName(e.target.value);

            setCurrentClient({
                name: matchedClient["name"],
                id: matchedClient["id"],
            });
        }
        if (type === LOCAL_EMAIL) setEmail(e.target.value);
        if (type === LOCAL_PASSWORD) setPassword(e.target.value);
        if (type === LOCAL_CONFIRM_PASSWORD) setConfirmPassword(e.target.value);
    }

    const getClientByName = (name) => {
        return clients.find(function (client) {
            return client.name === name;
        });
    };
    const getClientById = (id, clientsList) => {
        return clientsList.find(function (client) {
            return client.id === id;
        });
    };

    const fetchClientsUserAndGroups = async () => {
        const userResponse = await axios.get(GET_USER_BY_ID_URL + `/${id}`, {
            headers: { Authorization: `Bearer ${jwt}` },
        });
        if (currentUser.role === "SUPERUSER") {
            const clientsResponse = await axios.get(
                GET_ALL_CLIENTS_URL + "?per_page=50",
                {
                    headers: { Authorization: `Bearer ${jwt}` },
                }
            );

            let clientsList = [];
            clientsResponse.data.clients.data.forEach((client) => {
                // getting only the list of active clients
                if (client.is_active)
                    clientsList.push({ name: client.name, id: client.id });
            });
            setClients(clientsList);
            const clientById = getClientById(
                userResponse.data.user.current_client,
                clientsList
            );
            setCurrentClient({
                name: clientById.name,
                id: clientById.current_client,
            });
        } else {
            setCurrentClient({
                name: userResponse.data.user.current_client_name,
                id: userResponse.data.user.current_client,
            });
        }

        const groupsResponse = await axios.get(GET_ALL_GROUPS_URL, {
            headers: { Authorization: `Bearer ${jwt}` },
        });

        let groupsList = [];
        groupsResponse.data.groups.data.forEach((group) => {
            if (
                "group_subscriptions" in userResponse.data.user &&
                userResponse.data.user.group_subscriptions.includes(group.id)
            )
                group.is_subscribed = true;
            else group.is_subscribed = false;
            groupsList.push(group);
        });
        setGroups(groupsList);
        setOriginalGroups(groupsList);

        setFirstname(userResponse.data.user.first_name);
        setLastname(userResponse.data.user.last_name);
        setRole(userResponse.data.user.role);
        setEmail(userResponse.data.user.email);
        setUser(userResponse.data.user);
    };

    useEffect(() => {
        fetchClientsUserAndGroups();
    }, []);

    const handleAddGroup = (group) => {
        let groupsList = _.cloneDeep(groups);
        groupsList.forEach((groupElement) => {
            if (groupElement.id === group.id) groupElement.is_subscribed = true;
        });
        setGroups(groupsList);
    };

    const handleRemoveGroup = (group) => {
        let groupsList = _.cloneDeep(groups);
        groupsList.forEach((groupElement) => {
            if (groupElement.id === group.id)
                groupElement.is_subscribed = false;
        });
        setGroups(groupsList);
    };

    function clearSuccessAndErrorMessages() {
        setFirstnameError(null);
        setLastnameError(null);
        setEmailError(null);
        setPasswordError(null);
        setConfirmPasswordError(null);
        setUserEditError(null);
        setSuccessMessage(null);
    }

    function validateAll() {
        if (!validateEmptyField(firstname)) {
            setFirstnameError("Please fill this field");
            return false;
        }
        if (!validateEmptyField(lastname)) {
            setLastnameError("Please fill this field");
            return false;
        }
        if (!validateEmail(email)) {
            setEmailError("Please provide a valid email");
            return false;
        }
        if (validateEmptyField(password) && !validatePassword(password)) {
            setPasswordError("Please provide a valid password");
            return false;
        } else if (password !== confirmPassword) {
            setConfirmPasswordError(
                "Password and Password Confirmation must be identical!"
            );
            return false;
        }
        return true;
    }

    const handleUserEdit = async (e) => {
        e.preventDefault();

        clearSuccessAndErrorMessages();

        if (validateAll()) {
            let updatedUserObject = {};
            if (firstname !== user.first_name)
                updatedUserObject["first_name"] = firstname.trim();
            if (lastname !== user.last_name)
                updatedUserObject["last_name"] = lastname.trim();
            if (role !== user.role) updatedUserObject["role"] = role;

            if (current_client !== user.current_client)
                updatedUserObject["current_client"] = current_client.id;

            if (email !== user.email) updatedUserObject["email"] = email.trim();
            if (password) updatedUserObject["password"] = password;
            if (confirmPassword)
                updatedUserObject["password_confirmation"] = confirmPassword;

            let add_groups = [],
                remove_groups = [];

            groups.forEach((group, index) => {
                if (
                    group.is_subscribed === true &&
                    originalGroups[index].is_subscribed === false
                )
                    add_groups.push(group.id);
                else if (
                    group.is_subscribed === false &&
                    originalGroups[index].is_subscribed === true
                )
                    remove_groups.push(group.id);
            });
            if (add_groups.length > 0)
                updatedUserObject["add_groups"] = add_groups;
            if (remove_groups.length > 0)
                updatedUserObject["remove_groups"] = remove_groups;

            try {
                const response = await axios.patch(
                    EDIT_USER_BY_ID_URL + `/${id}`,
                    updatedUserObject,
                    {
                        headers: { Authorization: `Bearer ${jwt}` },
                    }
                );

                if (response.status === 200) {
                    setSuccessMessage("User updated successfully");
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                    });
                    if (response.data.user.id === currentUser.id)
                        dispatch(addCurrentUser(response.data.user));
                }
            } catch (error) {
                if (error.response) {
                    setUserEditError(error.response.data.message);
                    if (error.response.data.errors)
                        if (error.response.data.errors.email)
                            setEmailError(error.response.data.errors.email);
                }
            }
        }
    };

    return (
        <>
            {jwt === null ? (
                // if the user is not logged in then redirect to login
                <Redirect to={LOGIN_ROUTE} />
            ) : (
                <div className="container-lg">
                    <ProjectNavbar />
                    <div className="container-fluid">
                        <div className="page-header">
                            <h1 className="d-inline">Edit User</h1>
                        </div>
                        <hr />
                        {successMessage !== null ? (
                            <Alert
                                color="success"
                                toggle={(e) => {
                                    e.preventDefault();
                                    setSuccessMessage(null);
                                }}
                            >
                                {successMessage}
                            </Alert>
                        ) : null}
                        {userEditError !== null ? (
                            <Alert
                                color="danger"
                                toggle={(e) => {
                                    e.preventDefault();
                                    setUserEditError(null);
                                }}
                            >
                                {userEditError}
                            </Alert>
                        ) : null}
                        {user !== null ? (
                            <Form onSubmit={handleUserEdit}>
                                <Row>
                                    <Col>
                                        <TextField
                                            label={LOCAL_FIRSTNAME}
                                            placeholder={`Enter ${LOCAL_FIRSTNAME}`}
                                            type="text"
                                            autofocus={true}
                                            value={firstname ?? user.first_name}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    LOCAL_FIRSTNAME
                                                )
                                            }
                                        />
                                        {firstnameError !== null ? (
                                            <p className="text-danger">
                                                {firstnameError}
                                            </p>
                                        ) : null}
                                    </Col>
                                    <Col>
                                        <TextField
                                            label={LOCAL_LASTNAME}
                                            placeholder={`Enter ${LOCAL_LASTNAME}`}
                                            type="text"
                                            value={lastname ?? user.last_name}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    LOCAL_LASTNAME
                                                )
                                            }
                                        />
                                        {lastnameError !== null ? (
                                            <p className="text-danger">
                                                {lastnameError}
                                            </p>
                                        ) : null}
                                    </Col>
                                </Row>
                                <FormGroup floating>
                                    <Input
                                        id={LOCAL_ROLE}
                                        name={LOCAL_ROLE}
                                        type="select"
                                        value={role ?? user.role}
                                        onChange={(e) =>
                                            handleInputChange(e, LOCAL_ROLE)
                                        }
                                    >
                                        {USER_ROLES_ENUM.map((role) => {
                                            return (
                                                <option key={role}>
                                                    {role}
                                                </option>
                                            );
                                        })}
                                    </Input>

                                    <Label htmlFor={LOCAL_ROLE}>
                                        {LOCAL_ROLE}
                                    </Label>
                                </FormGroup>
                                <FormGroup floating>
                                    <Input
                                        id={LOCAL_CLIENT}
                                        name={LOCAL_CLIENT}
                                        type="select"
                                        className="mb-2"
                                        disabled={
                                            currentUser.role !== "SUPERUSER"
                                        }
                                        value={
                                            current_client.name ??
                                            getClientById(user.current_client)[
                                                "name"
                                            ]
                                        }
                                        onChange={(e) =>
                                            handleInputChange(e, LOCAL_CLIENT)
                                        }
                                    >
                                        {currentUser.role === "SUPERUSER" ? (
                                            clients.map((client) => {
                                                return (
                                                    <option key={client.id}>
                                                        {client.name}
                                                    </option>
                                                );
                                            })
                                        ) : (
                                            <option key={current_client.id}>
                                                {current_client.name}
                                            </option>
                                        )}
                                    </Input>
                                    <Label htmlFor={LOCAL_CLIENT}>
                                        {LOCAL_CLIENT}
                                    </Label>
                                </FormGroup>
                                <TextField
                                    label={LOCAL_EMAIL}
                                    placeholder={`Enter ${LOCAL_EMAIL}`}
                                    type="email"
                                    value={email ?? user.email}
                                    onChange={(e) =>
                                        handleInputChange(e, LOCAL_EMAIL)
                                    }
                                />
                                {emailError !== null ? (
                                    <p className="text-danger">{emailError}</p>
                                ) : null}
                                <TextField
                                    label={LOCAL_PASSWORD}
                                    placeholder={`Enter ${LOCAL_PASSWORD}`}
                                    type="password"
                                    value={password}
                                    onChange={(e) =>
                                        handleInputChange(e, LOCAL_PASSWORD)
                                    }
                                />
                                {passwordError !== null ? (
                                    <p className="text-danger">
                                        {passwordError}
                                    </p>
                                ) : null}
                                <TextField
                                    label={LOCAL_CONFIRM_PASSWORD}
                                    placeholder={`Enter ${LOCAL_CONFIRM_PASSWORD}`}
                                    type="password"
                                    value={confirmPassword}
                                    onChange={(e) =>
                                        handleInputChange(
                                            e,
                                            LOCAL_CONFIRM_PASSWORD
                                        )
                                    }
                                />
                                {confirmPasswordError !== null ? (
                                    <p className="text-danger">
                                        {confirmPasswordError}
                                    </p>
                                ) : null}

                                <div className="mt-3">
                                    <Label className="fw-bold">
                                        Group Subscription
                                    </Label>
                                    <Table striped>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {groups.map((group) => {
                                                return (
                                                    <tr key={group.id}>
                                                        <td>{group.name}</td>
                                                        <td>
                                                            {
                                                                //  if type is PUBLIC GROUPS then check if seleted or not
                                                                group.is_subscribed ? (
                                                                    <Button
                                                                        color="danger"
                                                                        className="ms-2"
                                                                        outline
                                                                        onClick={async (
                                                                            e
                                                                        ) => {
                                                                            e.preventDefault();

                                                                            handleRemoveGroup(
                                                                                group
                                                                            );
                                                                        }}
                                                                    >
                                                                        Remove
                                                                    </Button>
                                                                ) : (
                                                                    <Button
                                                                        color="success"
                                                                        className="ms-2"
                                                                        outline
                                                                        onClick={async (
                                                                            e
                                                                        ) => {
                                                                            e.preventDefault();

                                                                            handleAddGroup(
                                                                                group
                                                                            );
                                                                        }}
                                                                    >
                                                                        Add
                                                                    </Button>
                                                                )
                                                            }
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                                <Button
                                    color="primary"
                                    type="submit"
                                    disabled={!user.is_active}
                                    title={
                                        !user.is_active
                                            ? "Please activate the user first!"
                                            : null
                                    }
                                    className="submit-button mb-5"
                                >
                                    Save User
                                </Button>
                            </Form>
                        ) : null}
                    </div>
                </div>
            )}
        </>
    );
};

export default EditUser;
