import {
    ADD_ALL_CLIENTS,
    EDIT_CLIENT_BY_INDEX,
    REMOVE_ALL_CLIENTS,
    REMOVE_CLIENT_BY_INDEX,
} from "./clientTypes.js";

export const addAllClients = (clients = []) => {
    return {
        type: ADD_ALL_CLIENTS,
        payload: clients,
    };
};

export const removeAllClients = () => {
    return {
        type: REMOVE_ALL_CLIENTS,
    };
};

export const editClientByIndex = (updatedClient = {}, id) => {
    return {
        type: EDIT_CLIENT_BY_INDEX,
        payload: { updatedClient: updatedClient, id: id },
    };
};

export const removeClientByIndex = (index) => {
    return {
        type: REMOVE_CLIENT_BY_INDEX,
        payload: index, // here payload is the index of the client to be deleted
    };
};
