import {
    ADD_ALL_GROUPS,
    EDIT_GROUP_BY_INDEX,
    REMOVE_ALL_GROUPS,
    REMOVE_GROUP_BY_INDEX,
} from "./groupTypes.js";

export const initialState = {
    groups: [],
};

const groupReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_ALL_GROUPS:
            return {
                ...state,
                groups: action.payload,
            };
        case REMOVE_ALL_GROUPS:
            return {
                ...state,
                groups: [],
            };
        case EDIT_GROUP_BY_INDEX:
            return {
                ...state,
                groups: state.groups.map((group) => {
                    if (group.id === action.payload.id)
                        group = action.payload.updatedGroup;
                    return group;
                }),
            };
        case REMOVE_GROUP_BY_INDEX:
            return {
                ...state,
                groups: [
                    // here action.payload is the index of the group to be deleted
                    ...state.groups.slice(0, action.payload),
                    ...state.groups.slice(action.payload + 1),
                ],
            };
        default:
            return state;
    }
};

export default groupReducer;
