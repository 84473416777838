import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Form,
    Row,
} from "reactstrap";
import {
    ADD_PUBLIC_SUBSCRIPTIONS,
    GET_ALL_PUBLIC_GROUPS_URL,
    PUBLIC_MEETING_ROUTE,
} from "../../constants";
import { addAllGroups, removeAllGroups } from "../../redux/group/groupActions";
import { validateEmail } from "../utils/validate";
import ProjectTable from "../widgets/projectTable";
import TextField from "../widgets/textField";

const Subscription = () => {
    const dispatch = useDispatch();
    const { client_slug } = useParams();

    const [email, setEmail] = useState("");

    const groups = useSelector((state) => state.groupReducer.groups);
    const [emailError, setEmailError] = useState(null);
    const [subscriptionError, setSubscriptionError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    async function fetchSubscriptions(e) {
        e.preventDefault();

        if (validateAll()) {
            const response = await axios.get(
                `${GET_ALL_PUBLIC_GROUPS_URL.replace(
                    ":client_slug",
                    client_slug
                )}?email=${email}`
            );

            let groupSubscriptionsList = [];
            response.data.groups.data.forEach((group) => {
                groupSubscriptionsList.push(group);
            });

            dispatch(addAllGroups(groupSubscriptionsList));
        }
    }

    useEffect(() => {
        return () => {
            dispatch(removeAllGroups());
        };
    }, [dispatch]);

    function handleEmailChange(e) {
        clearSuccessAndErrorMessages();
        setEmail(e.target.value);
    }
    function clearSuccessAndErrorMessages() {
        setEmailError(null);
        setSubscriptionError(null);
        setSuccessMessage(null);
    }

    function validateAll() {
        if (!validateEmail(email)) {
            setEmailError("Please provide a valid email");
            return false;
        }
        return true;
    }

    function handleSubmit(e) {
        e.preventDefault();

        clearSuccessAndErrorMessages();

        if (validateAll()) {
            let addGroupsIdList = [];
            let removeGroupsIdList = [];
            groups.forEach((group) => {
                if (group.is_selected) addGroupsIdList.push(group.id);
                else removeGroupsIdList.push(group.id);
            });

            axios
                .post(ADD_PUBLIC_SUBSCRIPTIONS, {
                    email: email,
                    add_groups: addGroupsIdList,
                    remove_groups: removeGroupsIdList,
                })
                .then((response) => {
                    if (response.status === 200) {
                        setSuccessMessage(response.data.message);
                    } else {
                        setSubscriptionError(response.data.message);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        setSubscriptionError(error.response.data.error);
                    }
                });
        }
    }

    return (
        <div className="container-lg">
            <div className="container-fluid">
                <div className="page-header mt-3">
                    <h1 className="d-inline">Groups</h1>
                    <Link
                        to={PUBLIC_MEETING_ROUTE.replace(
                            ":client_slug",
                            client_slug
                        )}
                        className="nounderline float-end btn btn-success"
                    >
                        Go to Meetings
                    </Link>
                </div>
                <hr />
                <Card>
                    <CardHeader>
                        <CardTitle>
                            Please enter your email to subscribe to the selected
                            groups
                        </CardTitle>
                    </CardHeader>
                    <CardBody>
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col>
                                    <TextField
                                        label={"Email"}
                                        placeholder={"Enter Email"}
                                        type="text"
                                        value={email}
                                        onChange={handleEmailChange}
                                    />
                                    {emailError !== null ? (
                                        <p className="text-danger">
                                            {emailError}
                                        </p>
                                    ) : null}
                                </Col>
                            </Row>
                            <Button
                                color="primary"
                                className="me-3"
                                onClick={fetchSubscriptions}
                            >
                                Fetch Subscriptions
                            </Button>
                            <Button
                                type="submit"
                                color="success"
                                disabled={
                                    groups === null || groups.length === 0
                                        ? true
                                        : false
                                }
                            >
                                Subscribe
                            </Button>
                            {subscriptionError !== null ? (
                                <p className="text-danger">
                                    {subscriptionError}
                                </p>
                            ) : null}
                            {successMessage !== null ? (
                                <p className="text-success">{successMessage}</p>
                            ) : null}
                        </Form>
                    </CardBody>
                </Card>
                <ProjectTable data={groups} type="PUBLIC GROUPS" />
            </div>
        </div>
    );
};

export default Subscription;
