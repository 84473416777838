import axios from "axios";
import { ACTIVATE_USER_BY_ID_URL } from "../../constants";
import { editUserByIndex } from "../../redux/user/userActions";

const handleActivateUser = async (user, jwt, dispatch) => {
    try {
        const response = await axios.patch(
            ACTIVATE_USER_BY_ID_URL.replace(":id", user.id),
            null, // sending null here as we don't want to send any data
            {
                headers: { Authorization: `Bearer ${jwt}` },
            }
        );

        if (response.status === 200) {
            const updatedUser = response.data.user;
            dispatch(editUserByIndex(updatedUser, updatedUser.id));

            return { success: true };
        }
        return { success: false, error: response };
    } catch (error) {
        return { success: false, error: error.response.data.error };
    }
};
export default handleActivateUser;
