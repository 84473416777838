import _ from "lodash";

const handleRemoveFileFromFilesToBeUploaded = (
    e,
    topicIndex,
    file,
    isMinuteFile = false,
    meeting,
    setMeeting,
    minuteFilesToBeUploaded,
    setMinuteFilesToBeUploaded,
    filesToBeUploaded,
    setFilesToBeUploaded
) => {
    e.preventDefault();

    let localMeeting = _.cloneDeep(meeting);
    if (isMinuteFile) {
        localMeeting.items[topicIndex].minuteFilesToBeUploaded =
            localMeeting.items[topicIndex].minuteFilesToBeUploaded.filter(
                (fileElement) =>
                    fileElement.topic_id === null
                        ? fileElement.file_name !== file.file_name &&
                          fileElement.file_description !== file.file_description
                        : parseInt(fileElement.topic_id) !==
                              parseInt(file.topic_id) &&
                          fileElement.file_name !== file.file_name
            );

        if (localMeeting.items[topicIndex].minuteFilesToBeUploaded.length === 0)
            delete localMeeting.items[topicIndex]["minuteFilesToBeUploaded"];

        let minuteFilesToBeUploadedList = _.cloneDeep(minuteFilesToBeUploaded);
        minuteFilesToBeUploadedList = minuteFilesToBeUploadedList.filter(
            (fileElement) =>
                fileElement.topic_id === null
                    ? fileElement.file_name !== file.file_name &&
                      fileElement.file_description !== file.file_description
                    : parseInt(fileElement.topic_id) !==
                          parseInt(file.topic_id) &&
                      fileElement.file_name !== file.file_name
        );

        setMinuteFilesToBeUploaded(minuteFilesToBeUploadedList);
    } else {
        localMeeting.items[topicIndex].filesToBeUploaded = localMeeting.items[
            topicIndex
        ].filesToBeUploaded.filter((fileElement) =>
            fileElement.topic_id === null
                ? fileElement.file_name !== file.file_name &&
                  fileElement.file_description !== file.file_description
                : parseInt(fileElement.topic_id) !== parseInt(file.topic_id) &&
                  fileElement.file_name !== file.file_name
        );

        if (localMeeting.items[topicIndex].filesToBeUploaded.length === 0)
            delete localMeeting.items[topicIndex]["filesToBeUploaded"];

        let filesToBeUploadedList = _.cloneDeep(filesToBeUploaded);
        filesToBeUploadedList = filesToBeUploadedList.filter((fileElement) =>
            fileElement.topic_id === null
                ? fileElement.file_name !== file.file_name &&
                  fileElement.file_description !== file.file_description
                : parseInt(fileElement.topic_id) !== parseInt(file.topic_id) &&
                  fileElement.file_name !== file.file_name
        );
        setFilesToBeUploaded(filesToBeUploadedList);
    }
    setMeeting(localMeeting);
};

export default handleRemoveFileFromFilesToBeUploaded;
