import { Button, Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import "../../styles/meetingHistory.css";

export const showMeetingHistory = (
    meetingHistory,
    isShowMeetingHistory,
    setIsShowMeetingHistory
) => {
    return (
        <Offcanvas isOpen={isShowMeetingHistory}>
            <OffcanvasHeader
                isOpen={isShowMeetingHistory}
                className="meeting-history-header"
            >
                Meeting History
                <Button
                    color="danger"
                    className="float-end"
                    onClick={(e) => {
                        e.preventDefault();
                        setIsShowMeetingHistory(false);
                    }}
                >
                    Close
                </Button>
            </OffcanvasHeader>
            <OffcanvasBody>
                {meetingHistory === null ? (
                    "No Meeting History"
                ) : (
                    <ul>
                        {meetingHistory.map((history) => {
                            return (
                                <li key={history.id}>
                                    <strong>{history.description}</strong> by{" "}
                                    <strong>
                                        {history.user_name ?? "Deleted User"}
                                    </strong>{" "}
                                    at <strong>{history.updated_at}</strong>
                                </li>
                            );
                        })}
                    </ul>
                )}
            </OffcanvasBody>
        </Offcanvas>
    );
};
