import _ from "lodash";
import { FAKE_UUID_IDENTIFIER } from "../../constants";

const handleFileChange = (
    e,
    fileDescription = "Default file Description",
    topicIndex,
    topic,
    isMinuteFile = false,
    meeting,
    setMeeting,
    minuteFilesToBeUploaded,
    setMinuteFilesToBeUploaded,
    filesToBeUploaded,
    setFilesToBeUploaded
) => {
    e.preventDefault();

    let localMeeting = _.cloneDeep(meeting);
    if (topic.id && topic.id.split(":")[0] === FAKE_UUID_IDENTIFIER)
        topic.id = null;
    const fileObject = {
        topic: topic,
        topic_id: topic.id,
        file: e.target.files[0],
        is_minute_file: isMinuteFile,
        file_name: e.target.files[0].name,
        file_description: fileDescription,
    };

    console.log(`fileObject = ${JSON.stringify(fileObject)}`);

    if (isMinuteFile) {
        if (
            "minuteFilesToBeUploaded" in localMeeting.items[topicIndex] ===
            false
        )
            localMeeting.items[topicIndex].minuteFilesToBeUploaded = [];

        localMeeting.items[topicIndex].minuteFilesToBeUploaded.push(fileObject);

        let minuteFilesToBeUploadedList = _.cloneDeep(minuteFilesToBeUploaded);
        minuteFilesToBeUploadedList.push(fileObject);
        setMinuteFilesToBeUploaded(minuteFilesToBeUploadedList);
    } else {
        if ("filesToBeUploaded" in localMeeting.items[topicIndex] === false)
            localMeeting.items[topicIndex].filesToBeUploaded = [];

        localMeeting.items[topicIndex].filesToBeUploaded.push(fileObject);

        let filesToBeUploadedList = _.cloneDeep(filesToBeUploaded);
        filesToBeUploadedList.push(fileObject);
        setFilesToBeUploaded(filesToBeUploadedList);
    }
    setMeeting(localMeeting);
};

export default handleFileChange;
