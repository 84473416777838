import { combineReducers } from "redux";
import authReducer from "./auth/authReducer";
import userReducer from "./user/userReducer";
import clientReducer from "./client/clientReducer";
import groupReducer from "./group/groupReducer";
import publicSubscriptionReducer from "./publicSubscription/publicSubscriptionReducer";
import templateReducer from "./template/templateReducer";
import meetingReducer from "./meeting/meetingReducer";

const rootReducer = combineReducers({
    authReducer,
    userReducer,
    clientReducer,
    groupReducer,
    publicSubscriptionReducer,
    templateReducer,
    meetingReducer,
});

export default rootReducer;
