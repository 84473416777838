import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { Button, Form, Label } from "reactstrap";
import {
    EDIT_GROUP_BY_ID_URL,
    GET_ALL_USERS_URL,
    GET_GROUP_BY_ID_URL,
    GROUPS_ROUTE,
    LOGIN_ROUTE,
} from "../../constants";
import { capitalize } from "../utils/stringUtils";
import { validateEmptyField } from "../utils/validate";
import ProjectNavbar from "../widgets/projectNavbar";
import TextField from "../widgets/textField";
import "./../../styles/createGroup.css";
import "./../../styles/editGroup.css";

const EditGroup = () => {
    const authReducer = useSelector((state) => state.authReducer);
    const jwt = authReducer.jwt;
    const currentUser = authReducer.currentUser;

    const history = useHistory();

    const { id } = useParams();

    const [name, setName] = useState(null);
    const [nameError, setNameError] = useState(null);
    const [createGroupError, setEditGroupError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const [group, setGroup] = useState(null);
    const [users, setUsers] = useState([]);
    const [usersSelection, setUsersSelection] = useState([]);
    const [usersSelected, setUsersSelected] = useState([]);
    const [usersSelectedError, setUsersSelectedError] = useState(null);

    const LOCAL_NAME = "Name";

    function handleInputChange(e, type) {
        e.preventDefault();

        clearSuccessAndErrorMessages();
        if (type === LOCAL_NAME) {
            e.target.value = capitalize(e.target.value);
            setName(e.target.value);
        }
    }

    const existsInUsersSelected = (usersSelectedList, user) => {
        var i;
        for (i = 0; i < usersSelectedList.length; i++) {
            if (user.id === usersSelectedList[i].id) {
                return true;
            }
        }
        return false;
    };

    async function fetchGroupsAndUsers() {
        const response = await axios.get(GET_ALL_USERS_URL + "?per_page=1000", {
            headers: { Authorization: `Bearer ${jwt}` },
        });

        const groupResponse = await axios.get(GET_GROUP_BY_ID_URL + `/${id}`, {
            headers: { Authorization: `Bearer ${jwt}` },
        });

        setGroup(groupResponse.data.group);

        setName(groupResponse.data.group.name);
        let usersSelectedList = [];
        groupResponse.data.group.users.forEach((user) => {
            usersSelectedList.push({
                name: `${user.last_name}, ${user.first_name}`,
                id: user.id,
            });
        });
        setUsersSelected(usersSelectedList);

        let usersList = [],
            usersSelectionList = [];
        response.data.users.data.forEach((user) => {
            // getting only the list of active users
            if (user.is_active) {
                usersList.push({
                    name: `${user.last_name}, ${user.first_name}`,
                    id: user.id,
                });
                if (!existsInUsersSelected(usersSelectedList, user))
                    usersSelectionList.push({
                        name: `${user.last_name}, ${user.first_name}`,
                        id: user.id,
                    });
            }
        });
        setUsers(usersList);
        setUsersSelection(usersSelectionList);
    }

    useEffect(() => {
        fetchGroupsAndUsers();
    }, []);

    function clearSuccessAndErrorMessages() {
        setNameError(null);
        setEditGroupError(null);
        setSuccessMessage(null);
    }

    function validateAll() {
        if (!validateEmptyField(name)) {
            setNameError("Please fill this field!");
            return false;
        }
        if (usersSelected.length === 0) {
            setUsersSelectedError("Please select at least one user!");
            return false;
        }
        return true;
    }

    const userSelectionToSelected = (e, selectedUser) => {
        e.preventDefault();
        let usersSelectedList = usersSelected;
        usersSelectedList.push(selectedUser);

        setUsersSelected(usersSelectedList);

        let usersSelectionList = usersSelection;
        usersSelectionList = usersSelectionList.filter(function (user) {
            return user.id !== selectedUser.id;
        });

        setUsersSelection(usersSelectionList);
    };

    const userSelectedToSelection = (e, nonSelectedUser) => {
        e.preventDefault();

        let usersSelectionList = usersSelection;
        usersSelectionList.push(nonSelectedUser);

        setUsersSelection(usersSelectionList);

        let usersSelectedList = usersSelected;
        usersSelectedList = usersSelectedList.filter(function (user) {
            return user.id !== nonSelectedUser.id;
        });

        setUsersSelected(usersSelectedList);
    };

    const handleEditGroup = async (e) => {
        e.preventDefault();

        clearSuccessAndErrorMessages();

        if (validateAll()) {
            let updatedGroupObject = {};
            if (name !== group.name) updatedGroupObject["name"] = name.trim();

            let usersSelectedIdList = [];
            usersSelected.forEach((userSelected) => {
                usersSelectedIdList.push(userSelected.id);
            });
            let groupUsersIdList = [];
            group.users.forEach((groupUser) => {
                groupUsersIdList.push(groupUser.id);
            });
            let addUsers = [],
                removeUsers = [];
            usersSelectedIdList.forEach((userSelectedId) => {
                if (!groupUsersIdList.includes(userSelectedId))
                    addUsers.push(userSelectedId);
            });
            groupUsersIdList.forEach((groupUserId) => {
                if (!usersSelectedIdList.includes(groupUserId))
                    removeUsers.push(groupUserId);
            });

            if (addUsers.length !== 0)
                updatedGroupObject["add_users"] = addUsers;
            if (removeUsers.length !== 0)
                updatedGroupObject["remove_users"] = removeUsers;

            try {
                const response = await axios.patch(
                    EDIT_GROUP_BY_ID_URL + `/${group.id}`,
                    updatedGroupObject,
                    {
                        headers: { Authorization: `Bearer ${jwt}` },
                    }
                );
                if (response.status === 200) {
                    setSuccessMessage("Group updated successfully");
                    history.push(GROUPS_ROUTE);
                }
            } catch (error) {
                if (error.response) {
                    setEditGroupError(error.response.data.error);
                }
            }
        }
    };

    return (
        <>
            {jwt === null ? (
                // if the user is not logged in then redirect to login
                <Redirect to={LOGIN_ROUTE} />
            ) : (
                <div className="container-lg">
                    <ProjectNavbar />
                    <div className="container-fluid">
                        <div className="page-header">
                            <h1 className="d-inline">Edit Group</h1>
                        </div>
                        <hr />
                        {group ? (
                            <Form onSubmit={handleEditGroup}>
                                <TextField
                                    label={LOCAL_NAME}
                                    placeholder={`Enter ${LOCAL_NAME}`}
                                    type="text"
                                    autofocus={true}
                                    value={name ?? ""}
                                    onChange={(e) =>
                                        handleInputChange(e, LOCAL_NAME)
                                    }
                                />
                                {nameError !== null ? (
                                    <p className="text-danger">{nameError}</p>
                                ) : null}
                                <Label>Users</Label>
                                <div className="label-container d-flex justify-content-between mt-2">
                                    <Label>Client Users</Label>
                                    <Label>Selected Users</Label>
                                </div>
                                <div className="user-selection-container d-flex justify-content-between mb-4">
                                    <div
                                        className="user-selection d-inline border rounded"
                                        width="70%"
                                    >
                                        <ul>
                                            {usersSelection.map(
                                                (user, index) => {
                                                    return (
                                                        <li
                                                            key={user.id}
                                                            onClick={(e) =>
                                                                userSelectionToSelected(
                                                                    e,
                                                                    user
                                                                )
                                                            }
                                                        >
                                                            {user.name}
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </ul>
                                    </div>
                                    <div
                                        className="user-selected d-inline border rounded"
                                        width="70%"
                                    >
                                        <ul>
                                            {usersSelected.map(
                                                (user, index) => {
                                                    return (
                                                        <li
                                                            key={user.id}
                                                            onClick={(e) =>
                                                                userSelectedToSelection(
                                                                    e,
                                                                    user
                                                                )
                                                            }
                                                        >
                                                            {user.name}
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </ul>
                                    </div>
                                </div>
                                {usersSelectedError !== null ? (
                                    <p className="text-danger">
                                        {usersSelectedError}
                                    </p>
                                ) : null}
                                {createGroupError !== null ? (
                                    <p className="text-danger">
                                        {createGroupError}
                                    </p>
                                ) : null}
                                {successMessage !== null ? (
                                    <p className="text-success">
                                        {successMessage}
                                    </p>
                                ) : null}
                                <Button
                                    color="primary"
                                    type="submit"
                                    disabled={
                                        currentUser.role === "USER" ||
                                        !group.is_active
                                            ? true
                                            : false
                                    }
                                    title={
                                        !group.is_active
                                            ? "Please activate the group first!"
                                            : null
                                    }
                                    className="submit-button"
                                >
                                    Save Group
                                </Button>
                            </Form>
                        ) : null}
                    </div>
                </div>
            )}
        </>
    );
};

export default EditGroup;
