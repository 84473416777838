const updateOrders = (meetingOrTemplate, items, update_items_id_type_list, setUpdateItemsIdTypeList, checkIfItemExistsInUpdateList) => {
    console.log(`inside updateOrders()`);
    console.log(`items = ${JSON.stringify(items)}`);
    console.log(`meetingOrTemplate = ${JSON.stringify(meetingOrTemplate.items)}`);
    for (let i = 0; i < items.length; i++) {
        console.log(`inside updateOrders loop`);
        if (items[i].id) {
            console.log(`if(items[i].id)`);
            const newItem = items[i];
            const originalItemResult = getOriginalItem(meetingOrTemplate, newItem.id);
            console.log(`originalItemResult = ${JSON.stringify(originalItemResult)}`);
            const originalItem = originalItemResult.originalItem;
            const originalIndex = originalItemResult.originalIndex;
            console.log(`newItem = ${JSON.stringify(newItem)}`);
            console.log(`originalItem = ${JSON.stringify(originalItem)}`);
            if (newItem.order !== originalItem.order) {
                console.log(`INSIDE i=${i} if newItem.order = ${newItem.order} and originalItem.order = ${originalItem.order}`);
                if (
                    !checkIfItemExistsInUpdateList(
                        update_items_id_type_list,
                        meetingOrTemplate,
                        originalIndex
                    )
                ) {
                    console.log(`BEFORE update list set state ${JSON.stringify(originalItem)}`);
                    setUpdateItemsIdTypeList((update_items_id_type_list) => {
                        update_items_id_type_list = [
                            ...update_items_id_type_list,
                            {
                                id: originalItem.id,
                                type: originalItem.type,
                            },
                        ];
                        return update_items_id_type_list;
                    });
                }
            }
        }
        console.log(`update list = ${JSON.stringify(update_items_id_type_list)}`);
    }

    console.log(`LOOP ENDS updte list = ${JSON.stringify(update_items_id_type_list)}`);
}

const getOriginalItem = (meetingOrTemplate, id) => {
    console.log(`meetingOrTemplate = ${JSON.stringify(meetingOrTemplate)}`);
    console.log(`before loop and meetingOrTemplate.length = ${meetingOrTemplate.items.length}`);
    for (let index = 0; index < meetingOrTemplate.items.length; index++) {
        console.log(`index = ${index}`);
        if (meetingOrTemplate.items[index].id === id)
            return { originalItem: meetingOrTemplate.items[index], originalIndex: index };
    }
    console.log(`after loop`);
}

export default updateOrders;