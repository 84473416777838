import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Pagination,
    PaginationItem,
    PaginationLink,
} from "reactstrap";
import {
    CREATE_USER_ROUTE,
    GET_ALL_USERS_URL,
    ITEMS_PER_PAGE_LIST,
    LOGIN_ROUTE,
} from "../../constants";
import { addAllUsers, removeAllUsers } from "../../redux/user/userActions";
import ProjectNavbar from "../widgets/projectNavbar";
import ProjectTable from "../widgets/projectTable";

const Users = () => {
    const jwt = useSelector((state) => state.authReducer.jwt);
    const currentUser = useSelector((state) => state.authReducer.currentUser);
    const users = useSelector((state) => state.userReducer.users);
    const dispatch = useDispatch();

    const [perPage, setPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    async function fetchUsers() {
        const response = await axios(
            GET_ALL_USERS_URL + `?per_page=${perPage}&page=${pageNumber}`,
            {
                headers: { Authorization: `Bearer ${jwt}` },
            }
        );

        let usersList = [];
        response.data.users.data.forEach((user) => {
            if (currentUser.role === "SUPERUSER") usersList.push(user);
            else if (user.is_active) usersList.push(user);
        });
        setTotalItems(response.data.users.total);

        dispatch(addAllUsers(usersList));
    }
    useEffect(() => {
        fetchUsers();
        return () => {
            dispatch(removeAllUsers());
        };
    }, [dispatch, perPage, pageNumber]);

    function handleItemsPerPageChange(e, newPerPage) {
        e.preventDefault();

        setPerPage(newPerPage);
        setPageNumber(1);
    }
    var paginationLinks = [];
    for (var i = 1; i <= Math.ceil(totalItems / perPage); i++) {
        paginationLinks.push(i);
    }

    return (
        <>
            {jwt === null ? (
                // if the user is not logged in then redirect to login
                <Redirect to={LOGIN_ROUTE} />
            ) : (
                // ) : isSessionExpired ? (
                //     <ErrorModal />
                <div className="container-lg">
                    <ProjectNavbar />
                    <div className="container-fluid">
                        <div className="page-header">
                            <h1 className="d-inline">User</h1>
                            {currentUser.role !== "USER" ? (
                                <Link
                                    to={CREATE_USER_ROUTE}
                                    className="btn btn-outline-success float-end"
                                >
                                    Create User
                                </Link>
                            ) : null}
                        </div>
                        <hr />
                        <div className="my-4">
                            Show
                            <Dropdown
                                isOpen={dropdownOpen}
                                toggle={toggle}
                                className="d-inline mx-2"
                            >
                                <DropdownToggle caret>{perPage}</DropdownToggle>
                                <DropdownMenu>
                                    {ITEMS_PER_PAGE_LIST.map((itemPerPage) => {
                                        return (
                                            <DropdownItem
                                                key={itemPerPage}
                                                onClick={(e) =>
                                                    handleItemsPerPageChange(
                                                        e,
                                                        itemPerPage
                                                    )
                                                }
                                            >
                                                {itemPerPage}
                                            </DropdownItem>
                                        );
                                    })}
                                </DropdownMenu>
                            </Dropdown>
                            entries
                        </div>
                        <ProjectTable data={users} type="USERS" />
                        <Pagination
                            aria-label="Page navigation example"
                            className="justify-content-center"
                        >
                            {paginationLinks.map((paginationLink) => (
                                <PaginationItem
                                    key={paginationLink}
                                    active={pageNumber === paginationLink}
                                >
                                    <PaginationLink
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (pageNumber !== paginationLink)
                                                setPageNumber(paginationLink);
                                        }}
                                    >
                                        {paginationLink}
                                    </PaginationLink>
                                </PaginationItem>
                            ))}
                        </Pagination>
                    </div>
                </div>
            )}
        </>
    );
};

export default Users;
