import {
    ADD_ALL_CLIENTS,
    EDIT_CLIENT_BY_INDEX,
    REMOVE_ALL_CLIENTS,
    REMOVE_CLIENT_BY_INDEX,
} from "./clientTypes.js";

export const initialState = {
    clients: [],
};

const clientReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_ALL_CLIENTS:
            return {
                ...state,
                clients: action.payload,
            };
        case REMOVE_ALL_CLIENTS:
            return {
                ...state,
                clients: [],
            };
        case EDIT_CLIENT_BY_INDEX:
            return {
                ...state,
                clients: state.clients.map((client) => {
                    if (client.id === action.payload.id)
                        client = action.payload.updatedClient;
                    return client;
                }),
            };
        case REMOVE_CLIENT_BY_INDEX:
            return {
                ...state,
                clients: [
                    // here action.payload is the index of the client to be deleted
                    ...state.clients.slice(0, action.payload),
                    ...state.clients.slice(action.payload + 1),
                ],
            };
        default:
            return state;
    }
};

export default clientReducer;
