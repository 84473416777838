import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Pagination,
    PaginationItem,
    PaginationLink,
} from "reactstrap";
import {
    LOGIN_ROUTE,
    GET_ALL_CLIENTS_URL,
    CREATE_CLIENT_ROUTE,
    ITEMS_PER_PAGE_LIST,
} from "../../constants";
import {
    addAllClients,
    removeAllClients,
} from "../../redux/client/clientActions";
import ProjectNavbar from "../widgets/projectNavbar";
import ProjectTable from "../widgets/projectTable";

const Clients = () => {
    const jwt = useSelector((state) => state.authReducer.jwt);
    const currentUser = useSelector((state) => state.authReducer.currentUser);
    const clients = useSelector((state) => state.clientReducer.clients);
    const dispatch = useDispatch();

    const [perPage, setPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    async function fetchClients() {
        const response = await axios(
            GET_ALL_CLIENTS_URL + `?per_page=${perPage}&page=${pageNumber}`,
            {
                headers: { Authorization: `Bearer ${jwt}` },
            }
        );

        let clientsList = [];
        response.data.clients.data.forEach((user) => {
            clientsList.push(user);
        });
        setTotalItems(response.data.clients.total);

        dispatch(addAllClients(clientsList));
    }
    useEffect(() => {
        fetchClients();
        return () => {
            dispatch(removeAllClients());
        };
    }, [dispatch, perPage, pageNumber]);

    function handleItemsPerPageChange(e, newPerPage) {
        e.preventDefault();

        setPerPage(newPerPage);
        setPageNumber(1);
    }

    var paginationLinks = [];
    for (var i = 1; i <= Math.ceil(totalItems / perPage); i++) {
        paginationLinks.push(i);
    }

    return (
        <>
            {jwt === null ? (
                // if the user is not logged in then redirect to login
                <Redirect to={LOGIN_ROUTE} />
            ) : (
                <div className="container-lg">
                    <ProjectNavbar />
                    <div className="container-fluid">
                        <div className="page-header">
                            <h1 className="d-inline">Clients</h1>
                            {currentUser.role === "SUPERUSER" ? (
                                <Link
                                    to={CREATE_CLIENT_ROUTE}
                                    className="btn btn-outline-success float-end"
                                >
                                    Create Client
                                </Link>
                            ) : null}
                        </div>
                        <hr />
                        <div className="my-4">
                            Show
                            <Dropdown
                                isOpen={dropdownOpen}
                                toggle={toggle}
                                className="d-inline mx-2"
                            >
                                <DropdownToggle caret>{perPage}</DropdownToggle>
                                <DropdownMenu>
                                    {ITEMS_PER_PAGE_LIST.map((itemPerPage) => {
                                        return (
                                            <DropdownItem
                                                key={itemPerPage}
                                                onClick={(e) =>
                                                    handleItemsPerPageChange(
                                                        e,
                                                        itemPerPage
                                                    )
                                                }
                                            >
                                                {itemPerPage}
                                            </DropdownItem>
                                        );
                                    })}
                                </DropdownMenu>
                            </Dropdown>
                            entries
                        </div>
                        <ProjectTable data={clients} type="CLIENTS" />
                        <Pagination
                            aria-label="Page navigation example"
                            className="justify-content-center"
                        >
                            {paginationLinks.map((paginationLink) => (
                                <PaginationItem
                                    key={paginationLink}
                                    active={pageNumber === paginationLink}
                                >
                                    <PaginationLink
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (pageNumber !== paginationLink)
                                                setPageNumber(paginationLink);
                                        }}
                                    >
                                        {paginationLink}
                                    </PaginationLink>
                                </PaginationItem>
                            ))}
                        </Pagination>
                    </div>
                </div>
            )}
        </>
    );
};

export default Clients;
