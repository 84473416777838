import axios from "axios";
import { ACTIVATE_MEETING_BY_ID_URL } from "../../constants";
import { editMeetingByIndex } from "../../redux/meeting/meetingActions";

const handleActivateMeeting = async (meeting, jwt, dispatch) => {
    try {
        const response = await axios.patch(
            ACTIVATE_MEETING_BY_ID_URL.replace(":id", meeting.id),
            null, // sending null here as we don't want to send any data
            {
                headers: { Authorization: `Bearer ${jwt}` },
            }
        );

        if (response.status === 200) {
            const updatedMeeting = response.data.meeting;
            dispatch(editMeetingByIndex(updatedMeeting, updatedMeeting.id));

            return { success: true };
        }
        return { success: false, error: response };
    } catch (error) {
        return { success: false, error: error.response.data.error };
    }
};
export default handleActivateMeeting;
