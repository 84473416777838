import {
    ADD_ALL_PUBILC_SUBSCRIPTIONS,
    EDIT_PUBILC_SUBSCRIPTION_BY_INDEX,
    REMOVE_ALL_PUBILC_SUBSCRIPTIONS,
    REMOVE_PUBILC_SUBSCRIPTION_BY_INDEX,
} from "./publicSubscriptionTypes.js";

export const addAllPublicSubscriptions = (publicSubscriptions = []) => {
    return {
        type: ADD_ALL_PUBILC_SUBSCRIPTIONS,
        payload: publicSubscriptions,
    };
};

export const removeAllPublicSubscriptions = () => {
    return {
        type: REMOVE_ALL_PUBILC_SUBSCRIPTIONS,
    };
};

export const editPublicSubscriptionByIndex = (
    updatedpublicSubscription = {},
    id
) => {
    return {
        type: EDIT_PUBILC_SUBSCRIPTION_BY_INDEX,
        payload: {
            updatedpublicSubscription: updatedpublicSubscription,
            id: id,
        },
    };
};

export const removePublicSubscriptionByIndex = (index) => {
    return {
        type: REMOVE_PUBILC_SUBSCRIPTION_BY_INDEX,
        payload: index,
    };
};
