import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PUBLIC_UNSUBSCRIPTIONS } from "../../constants";

const Unsubscribe = () => {
    const { email, group_id } = useParams();

    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        handleUnsubscribe();
    });

    const handleUnsubscribe = () => {
        axios
            .post(
                `${PUBLIC_UNSUBSCRIPTIONS}?email=${email}&group_id=${group_id}`
            )
            .then((response) => {
                if (response.status === 200) {
                    setSuccess("Unsubscribed successfully");
                } else {
                    setError("Unsubscribe unsuccessfull");
                }
            })
            .catch((error) => {
                if (error.response) {
                    setError("Unsubscribe unsuccessfull");
                }
            });
    };

    return (
        <div>
            <h1>Unsubscribe</h1>
            <div>
                Email: {email}
                Group_id: {group_id}
            </div>
            {success ? success : null}
            {error ? error : null}
        </div>
    );
};

export default Unsubscribe;
