import axios from "axios";
import { CREATE_GROUP_URL, GROUPS_ROUTE } from "../../constants";

const handleCreateGroup = async (
    e,
    clearSuccessAndErrorMessages,
    validateAll,
    name,
    usersSelected,
    jwt,
    setSuccessMessage,
    setCreateGroupError,
    setNameError,
    history
) => {
    e.preventDefault();

    clearSuccessAndErrorMessages();

    if (validateAll()) {
        let newGroupObject = {};
        newGroupObject["name"] = name.trim();
        let usersSelectedIdList = [];
        usersSelected.forEach((userSelected) => {
            usersSelectedIdList.push(userSelected.id);
        });
        newGroupObject["add_users"] = usersSelectedIdList;
        try {
            const response = await axios.post(
                CREATE_GROUP_URL,
                newGroupObject,
                {
                    headers: { Authorization: `Bearer ${jwt}` },
                }
            );
            if (response.status === 201) {
                setSuccessMessage("Group created successfully");
                history.push(GROUPS_ROUTE);
            }
        } catch (error) {
            if (error.response) {
                setCreateGroupError(error.response.data.error);
                if (error.response.data.errors)
                    if (error.response.data.errors.name)
                        setNameError(error.response.data.errors.name);
            }
        }
    }
};

export default handleCreateGroup;
