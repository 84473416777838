export const arrangeOrders = (array, mode) => {
    if (mode === "TEMPLATE")
        array.forEach((element, index) => {
            element.order = index + 1;
        });
    else if (mode === "MEETING")
        array.forEach((element, index) => {
            element.order = index + 1;
        });
};
