import {
    ADD_ALL_MEETINGS,
    EDIT_MEETING_BY_INDEX,
    REMOVE_ALL_MEETINGS,
    REMOVE_MEETING_BY_INDEX,
} from "./meetingTypes.js";

export const initialState = {
    meetings: [],
};

const meetingReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_ALL_MEETINGS:
            return {
                ...state,
                meetings: action.payload,
            };
        case REMOVE_ALL_MEETINGS:
            return {
                ...state,
                meetings: [],
            };
        case EDIT_MEETING_BY_INDEX:
            return {
                ...state,
                meetings: state.meetings.map((meeting) => {
                    if (meeting.id === action.payload.id)
                        meeting = action.payload.updatedMeeting;
                    return meeting;
                }),
            };
        case REMOVE_MEETING_BY_INDEX:
            return {
                ...state,
                meetings: [
                    // here action.payload is the index of the meeting to be deleted
                    ...state.meetings.slice(0, action.payload),
                    ...state.meetings.slice(action.payload + 1),
                ],
            };
        default:
            return state;
    }
};

export default meetingReducer;
