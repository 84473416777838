import axios from "axios";
import { ACTIVATE_CLIENT_BY_ID_URL } from "../../constants";
import { editClientByIndex } from "../../redux/client/clientActions";

const handleActivateClient = async (client, jwt, dispatch) => {
    try {
        const response = await axios.patch(
            ACTIVATE_CLIENT_BY_ID_URL.replace(":id", client.id),
            null, // sending null here as we don't want to send any data
            {
                headers: { Authorization: `Bearer ${jwt}` },
            }
        );

        if (response.status === 200) {
            const updatedClient = response.data.client;
            dispatch(editClientByIndex(updatedClient, updatedClient.id));

            return { success: true };
        }
        return { success: false, error: response };
    } catch (error) {
        return { success: false, error: error.response.data.error };
    }
};
export default handleActivateClient;
