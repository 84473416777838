import {
    ADD_ALL_TEMPLATES,
    EDIT_TEMPLATE_BY_INDEX,
    REMOVE_ALL_TEMPLATES,
    REMOVE_TEMPLATE_BY_INDEX,
} from "./templateTypes.js";

export const addAllTemplates = (templates = []) => {
    return {
        type: ADD_ALL_TEMPLATES,
        payload: templates,
    };
};

export const removeAllTemplates = () => {
    return {
        type: REMOVE_ALL_TEMPLATES,
    };
};

export const editTemplateByIndex = (updatedTemplate = {}, id) => {
    return {
        type: EDIT_TEMPLATE_BY_INDEX,
        payload: { updatedTemplate: updatedTemplate, id: id },
    };
};

export const removeTemplateByIndex = (index) => {
    return {
        type: REMOVE_TEMPLATE_BY_INDEX,
        payload: index,
    };
};
