export const getGroupByName = (name, groups) => {
    return groups.find(function (groupElement) {
        return groupElement.name === name;
    });
};

export const getGroupById = (id, groups) => {
    return groups.find(function (groupElement) {
        return groupElement.id === id;
    });
};
