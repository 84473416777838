import _ from "lodash";
import { arrangeOrders } from "./arrangeOrders";

let dragItemIndex = null;
export function drag(event, itemIndex) {
    dragItemIndex = itemIndex;

    event.dataTransfer.setData("text/uri-list", event.target.id);
}

export function allowDrop(event) {
    event.preventDefault();
}

export function drop(
    event,
    dropItemIndex,
    templateMeeting,
    setTemplateMeeting,
    dropForEntity
) {
    event.preventDefault();

    // NOTE: here templateMeeting and setTemplateMeeting is used for both templates and meetings
    // when called from createTemplate or editTemplate the variables will receive template and setTemplate states
    // whereas, when called from createMeeting or editMeeting the variables will receive meeting and setMeeting states

    if (dropForEntity === "TEMPLATE") {
        let localTemplate = _.cloneDeep(templateMeeting);
        const dragItem = localTemplate.items[dragItemIndex];
        localTemplate.items.splice(dragItemIndex, 1);
        localTemplate.items.splice(dropItemIndex, 0, dragItem);
        arrangeOrders([...localTemplate.items], dropForEntity);
        setTemplateMeeting(localTemplate);
    } else if (dropForEntity === "MEETING") {
        let localMeeting = _.cloneDeep(templateMeeting);
        const dragItem = localMeeting.items[dragItemIndex];
        localMeeting.items.splice(dragItemIndex, 1);
        localMeeting.items.splice(dropItemIndex, 0, dragItem);
        arrangeOrders([...localMeeting.items], dropForEntity);
        setTemplateMeeting(localMeeting);
    }
}
